import React, { useState } from "react";
import SubTitle from "../components/SubTitle";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import hearing_aids from "../utils/hearing-aids";
import { Link } from "react-router-dom";

const HearingSolution = () => {

  const [showContent, setShowContent] = useState("");
   const [showProducts, setShowProducts] = useState("touts");
   const [data, setData] = useState(hearing_aids);

  // Filter the Products based on the selected category
    const filteredProducts = (cat) => {
     if(cat === "touts"){
      setData(hearing_aids)
      return
     }
        const newProducts = hearing_aids.filter((state) => state.category.includes(cat))
        setData(newProducts)
    }

  return (
    <section className=" ">
      <SubTitle
        maintitle="Nos solutions sont"
        subTitle={["Personnalisé", "Sur mesure"]}
      />
      <div className=" flex flex-col gap-4 m-auto w-[90%] lg:w-[70%]">
        <p className=" text-[15px] text-[#5b636c] text-center">
          Les aides auditives sont disponibles dans une multitude de formats,
          chacun étant adapté à des pertes auditives et à des besoins
          spécifiques.
        </p>

        <p className=" text-[15px] text-[#5b636c] text-center">
          Nos solutions auditives peuvent aller de minuscules prothèses
          auditives invisibles qui s'insèrent dans l'oreille à celles qui se
          placent discrètement derrière les oreilles. Il existe différents
          niveaux de technologie et fonctionnalités, de la connectivité sans fil
          aux solutions rechargeables. Notre portefeuille de solutions peut
          répondre à tous les besoins auditifs.
        </p>
      </div>
      <div className=" mt-6 bg-custom-primary/10 py-5">
        <h1 className=' after:bottom-0 h-[40px] lg:h-[52px] after:translate-x-[-50%] after:left-[50%] after:translate-y-[-50%] relative after:absolute after:bg-custom-primary after:content-[""] after:h-[1px] after:w-[30%] w-fit m-auto capitalize text-[18px] lg:text-[28px] text-custom-primary'>
          Tout ce que vous devez savoir
        </h1>
        <div>
          <div className=" lg:w-[60%] m-auto mt-10">
            <div
              className={` ${
                showContent === "appareils"
                  ? "lg:h-[450px] h-[550px]"
                  : "h-[50px]"
              } duration-200 transition-all w-full px-2 overflow-hidden `}
            >
              <h2
                onClick={() =>
                  showContent === "appareils"
                    ? setShowContent("")
                    : setShowContent("appareils")
                }
                className=" duration-150 transition-all hover:text-custom-primary cursor-pointer text-[16px] md:[18px] lg:text-[22px] font-semibold w-full flex items-center justify-between "
              >
                Quels types d'appareils auditifs existe-t-il ?{" "}
                {showContent === "appareils" ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </h2>
              <ul className=" list-disc text-[15px] md:text-[17px] lg:[18px] flex mt-6 flex-col gap-2 pl-8 px-6">
                <li>
                  Aides auditives invisibles (CICs Completely-in-Canal) - Aides
                  auditives sur mesure (impression 3D) qui se placent
                  entièrement dans votre conduit auditif
                </li>
                <li>
                  Récepteur dans le conduit auditif (RIC) - Minuscule derrière
                  les oreilles avec un fil très fin qui est pratiquement
                  invisible, avec des fonctionnalités très puissantes et un son
                  aussi naturel que possible
                </li>
                <li>
                  Derrière l'oreille (BTE) - Le style classique, modernisé.
                  Beaucoup plus petit, mais plus facile à manipuler et à
                  manipuler. Idéal pour les enfants et les générations plus
                  âgées qui recherchent puissance et praticité
                </li>
                <li>
                  Rechargeable - Dites adieu aux piles jetables. Les aides
                  auditives rechargeables offrent 30 heures sur une seule
                  charge !
                </li>
                <li>
                  Bluetooth et résistant à l'eau - Toutes nos aides auditives
                  offrent des fonctionnalités de connectivité pour diffuser de
                  l'audio à partir d'appareils, qu'il s'agisse de CIC, RIC ou
                  BTE. Nos aides auditives hors de l'oreille sont toutes
                  résistantes à l'eau (IP68). La transpiration et l'humidité ne
                  sont plus un problème !
                </li>
              </ul>
            </div>
            <div
              className={`${
                showContent === "fonctionnent"
                  ? "h-[270px] lg:h-[250px]"
                  : "h-[50px]"
              } duration-200 transition-all w-full px-2 overflow-hidden `}
            >
              <h2
                onClick={() =>
                  showContent === "fonctionnent"
                    ? setShowContent("")
                    : setShowContent("fonctionnent")
                }
                className=" duration-150 transition-all hover:text-custom-primary cursor-pointer   text-[16px] md:[18px] lg:text-[22px] font-semibold w-full flex items-center justify-between "
              >
                Comment fonctionnent les appareils auditifs ?{" "}
                {showContent === "fonctionnent" ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </h2>
              <p className=" mt-6 pl-8 px-6 text-[15px] md:text-[17px] lg:[18px]">
                La miniaturisation des micropuces a révolutionné la technologie
                des prothèses auditives modernes. Imaginez qu'une puissance de
                calcul aussi élevée puisse tenir dans ces tailles miniatures !
                Le principe est le même : les microphones captent les sons, les
                transmettent au processeur et à l'amplificateur qui les
                convertit en sons via le récepteur. Les micropuces contiennent
                désormais des processus Bluetooth et disposent de batteries
                lithium-ion rechargeables.
              </p>
            </div>
            <div
              className={` ${
                showContent === "besoin" ? "h-[250px]" : " h-[50px]"
              } duration-200 transition-all w-full px-2 overflow-hidden`}
            >
              <h2
                onClick={() =>
                  showContent === "besoin"
                    ? setShowContent("")
                    : setShowContent("besoin")
                }
                className=" duration-150 transition-all hover:text-custom-primary cursor-pointer  text-[16px] md:[18px] lg:text-[22px] font-semibold w-full flex items-center justify-between "
              >
                Ai-je besoin d'un appareil auditif ?{" "}
                {showContent === "besoin" ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </h2>
              <p className="   mt-6  pl-8 px-6 text-[15px] md:text-[17px] lg:[18px]">
                Contrairement à la croyance populaire, les appareils auditifs ne
                sont pas adaptés aux pertes auditives sévères. La plupart des
                personnes souffrent de pertes auditives légères à modérées.
                Toute perte auditive non traitée peut avoir de graves
                conséquences sur notre vie sociale ou professionnelle. Si vous
                n’avez pas passé de test, contactez notre audiologiste pour
                prendre rendez-vous pour un audiogramme. Vous pouvez également
                passer notre test auditif en ligne.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-10 px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36">
        <ul className=" grid grid-cols-3  border-t border-b border-gray-500/45 capitalize sm:flex items-center gap-4 sm:h-[50px] ">
          <li onClick={()=> {
          setShowProducts('touts'); filteredProducts('touts')
          }} className={`cursor-pointer h-full  flex items-center justify-center before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${showProducts === "touts" ? ' before:block text-custom-primary' : 'before:hidden '}`}>Tous</li>
          <li  onClick={()=> {
           setShowProducts('Aides Auditives')  ; filteredProducts('Aides Auditives')
          }} className={`cursor-pointer h-full  flex items-center justify-center before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${showProducts === "Aides Auditives" ? ' before:block text-custom-primary' : 'before:hidden '}`}>Aides Auditives</li>
          
          <li  onClick={()=> {
           setShowProducts('Accessoires') ; filteredProducts('Accessoires')
          }} className={`cursor-pointer h-full  flex items-center justify-center before:-bottom-[1px] sm:before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${showProducts === "Accessoires" ? ' before:block text-custom-primary' : 'before:hidden '}`}>Accessoires</li>
         

        </ul>
        <div className=" grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-16 mt-6 mb-6" >
             {
              data.map((product,index) => (
                <Link to={`/hearing-aids/${product.gamme === "resound" ?  product.brand : product.gamme === "interton" ?  'interton/' + product.brand  :  product.id === 7 ? 'accessory/' + product.id : '../hearing-solutions'}`} key={index} className=" border border-gray-200 py-2 px-1" data-aos="zoom-in-up">
                  <span className=" uppercase w-full block  text-[12px]  bg-custom-primary text-white text-center ">{product.level}</span>
                  <a href="/" className=" group ">
                  <div className=" relative h-[250px] w-full overflow-hidden">
                    <img src={product.image} className=" group-hover:scale-105 transition-all duration-200 absolute w-full h-full object-cover" alt="" />
                  </div>
                  <h1 className=" text-[17px] font-medium w-full px-4  m-auto text-center mt-2">{product.mainTitle}</h1>
                  </a>
                  <span className=" text-center mr-auto text-custom-primary capitalize block">{product.category}</span>
                  <p className=" text-[15px] text-gray-500 text-center mt-3">
                    {
                      product.desc
                    }
                  </p>
                </Link>
              ))
             }
        </div>
      </div> 

      {/* <div className=" px-4 pt-10 sm:px-8 md:px-16 lg:px-24 xl:px-36">
        <h1 className=' after:bottom-0 h-[40px] lg:h-[52px] after:translate-x-[-50%] after:left-[50%] after:translate-y-[-50%] relative after:absolute after:bg-custom-primary after:content-[""] after:h-[1px] after:w-[30%] w-fit m-auto capitalize text-[18px] lg:text-[28px] text-custom-primary'>
          Resound Produits
        </h1>

        <div className=" grid xl:grid-cols-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {hearing_aids.map((item) => (
            <a href={`/hearing-aids/${item.category}`}>
              <div className=" flex flex-col gap-2 items-center p-4 my-20">
                <div className=" overflow-hidden group h-[160px] w-full relative ">
                  <img
                    src={item.imageBanner}
                    alt=""
                    className=" group-hover:scale-125 duration-200 transition-all absolute w-full h-full"
                  />
                </div>
                <h1 className=" text-center text-[15px]">{item.mainTitle}</h1>
              </div>
            </a>
          ))}
        </div>
      </div> */}
    </section>
  );
};

export default HearingSolution;
