import music  from "../assets/hearing Protection/music.png"
import iem  from "../assets/hearing Protection/iem.png"
import industrial  from "../assets/hearing Protection/industrial.jpg"
import shooting  from "../assets/hearing Protection/shooting.jpg"
import swim  from "../assets/hearing Protection/swim.jpg"

/*
Filtres pour la musique/DJ
Industriel
Bouchons de natation/sommeil

IEM (écouteurs intra-auriculaires)

*/
const  hearingProtection = [
    {
        id:1,
        name:"Filtres pour la musique/DJ",
        image: music,
        lien:"/hearing-protection"
    },
    {
        id:2,
        name:"Industriel",
        image: industrial,
        lien:"/hearing-protection"
    },
    {
        id:3,
        name:"Bouchons de natation/sommeil Chasse et tir",
        image: swim,
        lien:"/hearing-protection"
    },
    {
        id:4,
        name:"Chasse et tir",
        image: shooting,
        lien:"/hearing-protection"
    },
    {
        id:5,
        name:"IEM (écouteurs intra-auriculaires)",
        image: iem,
        lien:"/hearing-protection"
    },

]

 export default hearingProtection;