import React, { useState } from "react";
import TitleWithImage from "../components/TitleWithImage";
import bannerProtection from "../assets/hearing Protection/hearing-protection-banner.jpg";
import protectionAuditive from "../assets/hearing Protection/protectionAuditive.png";
import Music from "../components/PreotectionComponents/Music";
import Indistrial from "../components/PreotectionComponents/Indistrial";
import Swim from "../components/PreotectionComponents/Swim";
import Shooting from "../components/PreotectionComponents/Shooting";
import IEM from "../components/PreotectionComponents/IEM";

const HearingProtection = () => {
  const [showProtection, setshowProtections] = useState("musique");

  return (
    <div>
      <TitleWithImage
        title="Protection auditive et moniteurs intra-auriculaires"
        image={bannerProtection}
      />
      <div className="px-4 sm:px-8 flex-col lg:flex-row md:gap-[50px] items-center md:px-16 lg:px-24 xl:px-36 flex lg:items-start mt-[90px] lg:gap-[100px]">
        <img className=" h-[500px]" src={protectionAuditive} alt="" />
        <p className=" text-center text-[15px] text-[#5b636c] leading-8">
          La protection auditive joue un rôle crucial dans la prévention de la
          perte auditive, une condition qui peut avoir un impact profond sur
          notre qualité de vie. Une exposition excessive au bruit, qu’elle soit
          due à des machines bruyantes, à des concerts ou même à une utilisation
          prolongée d’écouteurs, peut endommager les structures délicates de nos
          oreilles et entraîner une perte auditive irréversible. En utilisant
          une protection auditive, nous créons une barrière qui réduit
          l’intensité du son qui atteint nos oreilles. Cela permet de minimiser
          le risque de perte auditive due au bruit et de préserver notre
          capacité à entendre.
          <br />
          <br />
          Les protecteurs auditifs personnalisés offrent l’avantage du confort,
          de la longévité et de la précision. Bien qu’ils soient légèrement plus
          chers que les jetables, ils éliminent le besoin d’achat répété et
          durent des années.
        </p>
      </div>

      <div className="">
        <ul className=" flex flex-wrap justify-center  py-3 bg-custom-primary text-[12px] sm:text-[13px] md:text-[14px] lg:text-[15px] text-white my-4">
          <li
            onClick={() => setshowProtections("musique")}
            className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
              showProtection === "musique"
                ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                : ""
            }`}
          >
            Filtres pour la musique/DJ
          </li>
          <li
            onClick={() => setshowProtections("Industriel")}
            className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
              showProtection === "Industriel"
                ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                : ""
            }`}
          >
            Industriel
          </li>
          <li
            onClick={() => setshowProtections("natation")}
            className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
              showProtection === "natation"
                ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                : ""
            }`}
          >
            Bouchons de natation/sommeil
          </li>
          <li
            onClick={() => setshowProtections("Chasse")}
            className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
              showProtection === "Chasse"
                ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                : ""
            }`}
          >
            Chasse et tir
          </li>
          <li
            onClick={() => setshowProtections("iem")}
            className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
              showProtection === "iem"
                ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                : ""
            }`}
          >
            IEM (écouteurs intra-auriculaires)
          </li>
        </ul>
        <div className="px-4 sm:px-8 md:px-16  lg:px-24 xl:px-36 py-10">
          {showProtection === "musique" ? <Music /> : ""}
          {showProtection === "Industriel" ? <Indistrial /> : ""}
          {showProtection === "natation" ? <Swim /> : ""}
          {showProtection === "Chasse" ? <Shooting /> : ""}
          {showProtection === "iem" ? <IEM /> : ""}
        </div>
      </div>
    </div>
  );
};

export default HearingProtection;
