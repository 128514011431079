import { useState } from "react";
import IOSdevices from "../components/IOSdevices";
import AndroidDevices from "../components/AndroidDevices";
import ResoundApp from "../components/ResoundApp";
function Compatibility() {
  const [showContent, setshowContent] = useState("android");

  return (
    <div class="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden">
      <div class="layout-container flex h-full grow flex-col">
        
        <div class="px-40 flex flex-1 justify-center py-5">
          <div class="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div class="flex flex-wrap justify-between gap-3 p-4">
              <p class="text-custom-primary text-4xl font-black leading-tight tracking-[-0.033em] min-w-72">
                Vérifiez la compatibilité de votre smartphone avec ReSound Smart
                3D
              </p>
            </div>
            <p class="text-[#111518] text-base font-normal leading-normal pb-3 pt-1 px-4">
              Les aides auditives ReSound se connectent à votre smartphone afin
              que vous puissiez diffuser des appels et du son directement dans
              vos oreilles. Vérifiez si votre téléphone est compatible avec nos
              aides auditives sans fil.
            </p>

            <ResoundApp />

            <h2 class="text-custom-primary text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Quel smartphone utilisez-vous ?{" "}
            </h2>
            <div class="flex justify-stretch">
              <div class="flex flex-1 gap-3 flex-wrap px-4 py-3 justify-start">
                <button
                  onClick={() => setshowContent("android")}
                  class={`flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-12 px-5  ${
                    showContent === "android"
                      ? "bg-custom-secondary text-[#f0f2f4]"
                      : "bg-[#f0f2f4] text-custom-secondary"
                  }  text-base font-bold leading-normal tracking-[0.015em]`}
                >
                  <span class="truncate">Android</span>
                </button>
                <button
                  onClick={() => setshowContent("ios")}
                  class={`flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-12 px-5  ${
                    showContent === "ios"
                      ? "bg-custom-secondary text-[#f0f2f4]"
                      : "bg-[#f0f2f4] text-custom-secondary"
                  }  text-base font-bold leading-normal tracking-[0.015em]`}
                >
                  <span class="truncate">iOS</span>
                </button>
              </div>
            </div>

            <h2 class="text-custom-primary text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Appareils {showContent === "ios" ? "iOS" : "Android"} compatibles
            </h2>
            {showContent === "ios" && <IOSdevices />}
            {showContent === "android" && <AndroidDevices />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compatibility;
