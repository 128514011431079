import TitleWithImage from "../components/TitleWithImage";
import ScreenMaison from "../assets/about/pourquoi.jpg";
import MPMstore from "../assets/about/aboutMPM.png";
const WhyMaisonParamed = () => {
  return (
    <div>
      <TitleWithImage title="Pourquoi Maison Paramed" image={ScreenMaison} />
      <div className="flex flex-col lg:flex-row items-center lg:items-start bg-white px-8 py-20">
        {/* Left Image Section */}
        <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
          <img
            src={MPMstore} // Replace with the actual image path
            alt="Houri Hearing Building"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Right Text Section */}
        <div className="lg:w-1/2 w-full lg:pl-8">
          <h2 className="text-custom-primary text-3xl font-bold mb-4">
            Pourquoi Maison Paramed
          </h2>
          <p className="text-gray-700 text-[15px] leading-relaxed mb-4">
            Découvrez Maison Paramed, votre expert de confiance en santé
            auditive au Maroc. Nous sommes passionnés par l’amélioration de la
            qualité de vie de nos patients en proposant des solutions auditives
            sur mesure et de haute qualité.
          </p>
          <p className="text-gray-700 text-[15px] leading-relaxed mb-4">
            Nos centres sont équipés des technologies les plus récentes, et
            notre équipe de professionnels expérimentés vous accompagne à chaque
            étape de votre parcours auditif. Que vous ayez besoin d'un bilan
            auditif, de choisir et ajuster un appareil auditif, ou d'un suivi
            personnalisé, Maison Paramed vous offre un service attentif, adapté
            à vos besoins spécifiques.
          </p>
          <p className="text-gray-700 text-[15px] leading-relaxed mb-4">
            Avec une approche globale de la santé auditive, nous organisons
            régulièrement des campagnes de sensibilisation et des événements
            pour souligner l’importance de protéger son audition. Nous sommes
            également engagés socialement, offrant des services gratuits ou à
            tarifs réduits aux personnes en situation de besoin, car nous
            croyons que l’accès aux soins auditifs de qualité est un droit pour
            tous.
          </p>
          <p className="text-gray-700 text-[15px] leading-relaxed mb-4">
            Faites confiance à Maison Paramed pour prendre soin de votre
            audition et améliorer votre bien-être. Prenez rendez-vous et découvrez la différence que
            peut apporter Maison Paramed dans votre vie. Ne tardez plus, prenez
            soin de votre audition avec Maison Paramed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyMaisonParamed;
