import React from "react";
import TitleWithImage from "../components/TitleWithImage";
import image from "../assets/guide.jpg";
// import pourquoiParamed from "../assets/about/pourquoi.jpg";
import pourquoiParamed from "../assets/services/img3.jpeg";

const About = () => {
  const Cards = [
    {
      name: "Pourquoi Maison Paramed",
      image: pourquoiParamed,
      lien: "/maison-paramed",
    },
    
  ];
  return (
    <section className=" ">
      <TitleWithImage image={image} title="à Propos Maison paramed" />
      <p className=" px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36 text-slate-600 text-[13px] md:text-[15px] my-6 lg:text-[16px] text-center">
        Maison Paramed est devenu synonyme d'excellence en matière de soins auditifs dans la
        région. Des technologies les plus avancées aux normes les plus élevées
        en audiologie, nous avons perfectionné notre processus pour vous offrir
        les meilleurs soins pour vous aider à atteindre votre potentiel auditif.
        Découvrez-en plus ci-dessous
      </p>
      {/* <div className="px-4 sm:px-8 md:px-16 w-full h-full lg:px-24 xl:px-36 flex justify-between flex-col md:flex-row gap-4 items-center mb-10"> */}
      <div className="px-4 w-full h-full flex justify-center flex-col md:flex-row  items-center mb-10">
        {Cards.map((item, index) => (
          <a href={item.lien}
            key={index}
            className=" flex items-center justify-center  relative w-[100%] md:w-[50%] h-[300px] md:h-[400px] overflow-hidden group cursor-pointer"
          >
            <img
              src={item.image}
              alt=""
              className=" absolute  w-full h-full object-cover z-0 group-hover:scale-125 duration-300 transition-all"
            />
            <div className=" absolute w-full h-full bg-custom-primary/40"></div>
            <span className=" z-20 relative py-1 px-3 md:py-2 md:p-6 sm:text-[15px] md:text-[16px] text-[13px] bg-custom-primary rounded-sm text-white">
              {item.name}
            </span>
          </a>
        ))}
      </div>
    </section>
  );
};

export default About;
