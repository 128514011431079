import React from "react";
import indistrial from "../../assets/hearing Protection/industrial.jpg";
import indProd from "../../assets/hearing Protection/ind-prod.png";

const Indistrial = () => {
  return (
    <div className=" flex flex-col gap-5 lg:gap-1 lg:flex-row" data-aos="fade-zoom-in">
      <div className="  flex items-start lg:w-[50%]  gap-2">
        <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] w-[60%] ">
          <img className=" absolute w-full h-full  object-cover" src={indistrial} alt="" />
        </div>
        <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] flex items-center justify-center w-[30%]">
          <img className=" w-full h-[150px]  object-cover" src={indProd} alt="" />
        </div>
      </div>
      <div className="lg:w-[50%] flex flex-col gap-6">
        <h2 className=" m-auto lg:m-0 capitalize lg:text-[25px] text-custom-primary">
          Industriel
        </h2>
        <p className=" text-[15px] text-[#5b636c] leading-8">
          La protection auditive dans les environnements industriels est de la
          plus haute importance en raison des niveaux élevés d'exposition au
          bruit auxquels les travailleurs sont souvent confrontés. Les secteurs
          tels que la construction, la fabrication, l'exploitation minière et
          l'aviation sont connus pour leurs environnements bruyants, où les
          travailleurs sont quotidiennement exposés à des machines, des
          équipements et des outils bruyants. Une exposition prolongée à ces
          bruits forts peut entraîner des dommages auditifs importants si des
          mesures appropriées ne sont pas prises.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8">
          L’utilisation d’appareils de protection auditive (APH) devient
          essentielle dans ces environnements pour atténuer le risque de perte
          auditive due au bruit. Ces équipements de protection fonctionnent en
          réduisant l’intensité du son qui atteint les oreilles, protégeant
          ainsi les travailleurs des effets nocifs d’un bruit excessif.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8">
          Implementing a comprehensive hearing conservation program is crucial
          in industrial settings. This program typically includes noise
          assessments to determine the noise levels in different areas, regular
          audiometric testing to monitor employees’ hearing health, and training
          sessions to raise awareness about the importance of hearing protection
          and proper usage of HPDs.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8">
          En donnant la priorité à la protection auditive en milieu industriel,
          les employeurs remplissent non seulement leurs obligations légales,
          mais favorisent également un environnement de travail sûr et sain pour
          leurs employés.
        </p>
      </div>
    </div>
  );
};

export default Indistrial;
