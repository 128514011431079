import React from "react";
import TitleWithImage from "../../components/TitleWithImage";
import image from "../../assets/guide.jpg";
import { BLOG } from "../../utils/IMAGES";
import blog31 from "../../assets/blog/blogmultimic.jpg";
import blog32 from "../../assets/blog/blog32.jpg";

function BlogMultiMic() {
  const description = [
    {
      text: "Lorsque vous souffrez d'une perte auditive, vous avez parfois l'impression d'assister à une fête sous l'eau : Vous pouvez voir tout le monde - et les réactions faciales peuvent vous indiquer que quelqu'un a raconté une blague grossière ou partagé quelque chose de triste, d'important ou de significatif - mais vous ne pouvez pas toujours entendre les punchlines, les indications ou d'autres communications.",
    },

    {
      text: "Bien entendu, les aides auditives sont un outil puissant pour lutter contre ce problème et améliorer votre capacité à entendre. Certaines personnes ne peuvent pas bénéficier entièrement des aides auditives en raison de la gravité de leur perte auditive.",
    },
    {
      text: "C'est là que le Multi Mic peut vous aider : la personne qui parle porte un petit microphone et sa parole est transmise par Bluetooth directement dans votre appareil auditif. L'utilisation de ces systèmes peut faire une différence significative.",
    },
    {
      text: "L'utilisation de Multi Mic (systèmes de microphones à distance) permet d'améliorer considérablement la reconnaissance de la parole par rapport aux aides auditives seules.",
    },
  ];

  const multimic = [
    {
      text: "Le ReSound Multi Mic se connecte directement à n'importe quelle aide auditive sans fil ReSound et étend la portée auditive jusqu'à 25 mètres (80 pieds) en visibilité directe.",
    },
    {
      text: "Le Resound Multi Mic peut également servir de microphone de table, se connecte aux systèmes de boucle et FM et dispose d'une entrée mini-jack pour transformer vos aides auditives en écouteurs.",
    },
  ];
  const howto = [
    {
      text: "En termes simples, un Multi Mic est un appareil séparé qui est utilisé pour amener le signal [sonore] d'une source extérieure directement à l'utilisateur de l'aide auditive. Ce micro est petit - il peut se clipser sur le revers de l'orateur - et s'associe sans fil à vos aides auditives via Bluetooth. Il permet de réduire le bruit et la distance pour transmettre les voix directement aux oreilles appareillées d'une personne malentendante.",
    },
    {
      text: "Placé sur une table, le Multi Mic se transforme en microphone de table, ce qui permet d'entendre ce que disent les personnes qui l'entourent. Le Multi Mic est doté d'une entrée mini-jack permettant de diffuser de l'audio à partir de pratiquement n'importe quel appareil doté d'une sortie casque.",
    },
  ];

  const backgroundNoise = [
    {
      text: "L'utilisation d'un Multi Mic permet d'améliorer le rapport signal/bruit (RSB) selon une petite étude impliquant des adultes et des enfants atteints de perte auditive bilatérale. C'est important : le signal est ce que vous voulez entendre (c'est la personne qui vous parle à la table du restaurant) et le bruit (les sons de fond des conversations des autres tables et le tintement des couverts) est ce qui peut vous empêcher d'entendre.",
    },
    {
      text: "Le problème s'aggrave lorsque les personnes sont plus éloignées. (La distance et le bruit de fond sont des facteurs qui rendent l'audition plus difficile).",
    },
    {
      text: "Les micros multiples accentuent le signal, mais pas le bruit. Le signal que vous voulez entendre, comme la personne qui parle, tient le Multi Mic ou le clipse sur elle lorsqu'elle parle, de sorte que le son entre directement dans les aides auditives de l'utilisateur, avec moins d'interférences extérieures. En d'autres termes, ils réduisent les bruits de fond et renforcent le son de la personne que vous souhaitez entendre.",
    },
  ];

  const whento = [
    {
      text: "Ils sont particulièrement utiles dans un restaurant ou dans un groupe, partout où il y a beaucoup de bruits de fond. Vous pouvez donner le Multi Mic à quelqu'un qui parle au restaurant ou dans une salle de conférence - ou même à votre professeur de yoga ou à un autre instructeur - afin que l'utilisateur de l'aide auditive puisse entendre plus directement et plus clairement.",
    },
    {
      text: "Si vous avez un enfant malentendant, ces appareils sont incroyablement utiles à l'école et à la maison. Les multi-mic permettent à votre enfant d'entendre plus de mots et améliorent l'attention, la confiance et l'indépendance.",
    },
  ];

  const drawbacks = [
    {
      text: "Vous devrez peut-être vous rendre chez votre audioprothésiste pour coupler correctement les aides auditives avec un micro déporté.",
    },
    {
      text: "Et toute cette diffusion sans fil réduit l'autonomie de la batterie. Supposons que vous ayez des appareils auditifs rechargeables au lithium-ion : ils ont généralement une autonomie de 30 heures, mais avec le streaming sans fil, cette autonomie tombe à 24 heures.",
    },
    {
      text: "Les multi-micros sont encore une autre chose à transporter. Vous devez avoir un appareil séparé à emporter partout avec vous pour en tirer profit.",
    },
  ];
  return (
    <section>
      {/* ---------- BLOG IMAGE  */}
      <TitleWithImage image={image} title="Protection auditive sur mesure" />
      <div className="flex px-8 py-10 justify-center flex-col max-w-4xl m-auto">
        <img className="" src={BLOG.Mic} alt="" />
        {/* ------- DESCRIPTION  */}
        <div className="py-14">
          {description.map((e, index) => (
            <p className="text-sm text-gray-600 leading-loose mb-6" key={index}>
              {e.text}
            </p>
          ))}
        </div>
        {/* ---------------  Resound Multi Mic.*/}
        <div className="py-14">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Resound Multi Mic.
          </p>

          {multimic.map((e, index) => (
            <p className="text-sm text-gray-600 leading-loose mb-6" key={index}>
              {e.text}
            </p>
          ))}

          <img className="py-10 m-auto" src={blog31} alt="" />
        </div>

        {/* ---------------  How does Multi Mic work?*/}
        <div className="py-14">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Comment fonctionne le Multi Mic ?
          </p>

          {howto.map((e, index) => (
            <p className="text-sm text-gray-600 leading-loose mb-6" key={index}>
              {e.text}
            </p>
          ))}
        </div>
        {/* ---------------  Dramatically reduces background noise.*/}
        <div className="py-14">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Réduit considérablement le bruit de fond.
          </p>

          {backgroundNoise.map((e, index) => (
            <p className="text-sm text-gray-600 leading-loose mb-6" key={index}>
              {e.text}
            </p>
          ))}
          <img className="py-10 m-auto" src={blog32} alt="" />
        </div>

        {/* ---------------  When to use a Multi Mic?*/}
        <div className="py-14">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Quand utiliser un multi-mic ?
          </p>

          {whento.map((e, index) => (
            <p className="text-sm text-gray-600 leading-loose mb-6" key={index}>
              {e.text}
            </p>
          ))}
        </div>
        {/* ---------------  Drawbacks to note.*/}
        <div className="py-14">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Inconvénients à noter.
          </p>

          {drawbacks.map((e, index) => (
            <p className="text-sm text-gray-600 leading-loose mb-6" key={index}>
              {e.text}
            </p>
          ))}
        </div>

        <div className="border-t border-b border-gray-400 border-solid">
          <p className="text-sm text-gray-600 leading-loose">15 août 2024</p>
        </div>
      </div>
    </section>
  );
}

export default BlogMultiMic;
