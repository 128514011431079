import {DB} from '../config/firebaseConfig';
import { collection, addDoc } from "firebase/firestore";

export const saveRequestToFireStore = async (fullname, phone, email) => {
    const request = {
        fullname, phone, email
    }
    if (email !== "" && fullname !== "" && phone !== "") {
        const docRef = await addDoc(collection(DB, "requests"), request);
    }
};


export const saveContactToFireStore = async (fullname, phone, email, message) => {
    const contact = {
        fullname, phone, email, message
    }
    if (email !== "" && fullname !== "" && phone !== "" && message !== "") {
        const docRef = await addDoc(collection(DB, "contacts"), contact);
    }
};
