import React from "react";
import TitleWithImage from "../../components/TitleWithImage";
import image from "../../assets/guide.jpg";
import blog2 from "../../assets/blog/blog2.jpg";
import blog21 from "../../assets/blog/blog21.jpg";
import { BLOG } from "../../utils/IMAGES";

function BlogHearingProtection() {
  const desc1 =
    " Lorsqu'il s'agit de protéger votre audition, les protections sonores génériques peuvent sembler être une solution rentable, mais elles n'offrent souvent pas la protection nécessaire.";
  // Benefits of using custom hearing protection
  const benefits = [
    {
      name: "1. UN AJUSTEMENT PARFAIT :",
      description:
        "Les protections acoustiques sur mesure sont fabriquées spécifiquement pour vos oreilles. Cela signifie qu'elles s'adaptent parfaitement à vos oreilles, offrant ainsi une protection maximale contre les sons nocifs.",
    },
    {
      name: "2. CONFORT :",
      description:
        "Fabriqués à partir de matériaux souples et confortables à porter pendant de longues périodes, ils sont conçus pour s'adapter parfaitement à vos oreilles sans provoquer d'inconfort ou d'irritation.",
    },
    {
      name: "3. DURABILITÉ :",
      description:
        "Il est fabriqué à partir de matériaux de haute qualité conçus pour durer, pour résister à l'usure et aux déchirures et pour être moins susceptibles de se casser ou de se détériorer avec le temps.",
    },
    {
      name: "4. MEILLEURE QUALITÉ SONORE :",
      description:
        "Les protections sonores personnalisées sont conçues pour offrir une qualité sonore optimale tout en protégeant vos oreilles. Elles sont conçues pour réduire les niveaux de bruit sans déformer ou étouffer le son.",
    },
    {
      name: "5. RENTABLE :",
      description:
        "Si les protections acoustiques sur mesure peuvent sembler plus coûteuses au départ, elles constituent une solution rentable à long terme. Elles sont durables, ce qui signifie que vous n'aurez pas besoin de les remplacer aussi souvent que des bouchons d'oreille ou des protège-oreilles génériques.",
    },
  ];
  return (
    <section>
      {/* ---------- BLOG IMAGE  */}
      <TitleWithImage image={image} title="Protection auditive sur mesure" />

      <div className="flex px-8 py-10 justify-center flex-col max-w-3xl m-auto">
        <img className="" src={BLOG.protection} alt="" />

        <div className="py-14">
          <p className="text-md text-custom-primary leading-relaxed">{desc1}</p>
          <img
            className="m-auto"
            src="https://mcusercontent.com/9c9071618fa95ffd3f5d90207/images/cdf379d3-4b3a-f600-35eb-f49d3f9f91ea.gif"
            alt=""
          />
        </div>
        <div className="">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            La perte auditive induite par le bruit
          </p>
          <p className="text-sm text-gray-600 leading-loose">
            • La perte auditive induite par le bruit est causée par l'exposition
            à un bruit fort et nocif et peut être temporaire ou permanente.{" "}
            <br />• Elle survient lorsque les structures de l'oreille interne
            sont endommagées par des bruits forts. La plupart du temps, les sons
            de notre environnement quotidien - comme la télévision, la
            circulation et les conversations ambiantes - se situent à un niveau
            sûr. Il arrive cependant que les sons deviennent trop forts ou
            durent trop longtemps. Cela peut entraîner une perte auditive due au
            bruit. Contrairement à d'autres types de perte auditive, la perte
            auditive due au bruit peut être évitée.
          </p>
          <img className="py-10 m-auto" src={blog2} alt="" />
          <img className="py-10 m-auto" src={blog21} alt="" />
        </div>

        <div className="py-10">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            À qui s'adresse-t-il ?
          </p>
          <p className="text-sm text-gray-600 leading-loose">
            Équipé d'un filtre acoustique ER avec atténuation plate. <br />{" "}
            <br />
            Il s'agit donc de la protection auditive idéale pour : <br />
            <br />
            • Les musiciens professionnels et amateurs, les DJ, les mélomanes,
            les pubs et les bars qui diffusent de la musique forte. <br />•
            Lorsqu'une atténuation subtile est nécessaire, par exemple dans les
            établissements de santé et d'enseignement. <br />• la chasse/le tir.
            <br /> • Zones industrielles
          </p>
        </div>

        <div className="py-10">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Avantages d'une protection acoustique sur mesure
          </p>
          {benefits.map((e, index) => (
            <div className="py-4">
              <p className="font-medium text-lg text-custom-secondary ">
                {e.name}
              </p>
              <p className="text-sm text-gray-600 leading-loose">
                {e.description}
              </p>
            </div>
          ))}
        </div>
        <div className="border-t border-b border-gray-400 border-solid">
          <p className="text-sm text-gray-600 leading-loose">15 août 2024</p>
        </div>
      </div>
    </section>
  );
}

export default BlogHearingProtection;
