import React from 'react';
import smartApp from '../assets/smartApp.png'
import ios from '../assets/apps/ios.png';
import android from '../assets/apps/android.png';
const ResoundApp = () => {
  return (
    // <section className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12 py-12 bg-white">
    // <section className="px-40 lg:px-30 md:px-30 sm:px-15 flex flex-col md:flex-row items-center justify-center r py-5 layout-content-container max-w-[960px]  ">
    <section className="flex flex-col lg:flex-row md:flex-row sm:flex-col xs:flex-col py-12 px-4">
      {/* Text Section */}
      <div className="md:w-full text-leftspace-y-4">
        <h2 className="text-2xl font-bold text-custom-primary ">
          Application ReSound Smart 3D<sup>TM</sup>
        </h2>
        <p className="text-gray-700 md:text-left sm:text-left py-4">
          Les utilisateurs des aides auditives ReSound ONE<sup>TM</sup>, ReSound LiNX Quattro<sup>TM</sup>, 
          ReSound ENZO Q<sup>TM</sup>, ReSound Key<sup>TM</sup>, ReSound LiNX 3D<sup>TM</sup> ou ReSound ENZO 3D<sup>TM</sup> 
          peuvent optimiser davantage leur expérience d'écoute grâce à notre application très avancée et intuitive.
        </p>
        <div className="flex justify-center md:justify-start space-x-4">
          {/* App Store Buttons */}
          <a href="https://apps.apple.com/fr/app/resound-smart-3d/id1134918918" target="_blank" rel="noreferrer">
            <img
              src={ios}
              alt="App Store"
              className="w-36"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=dk.resound.smart3d&hl=fr" target="_blank" rel="noreferrer">
            <img
              src={android}
              alt="Google Play Store"
              className="w-36"
            />
          </a>
        </div>
      </div>

      {/* Image Section */}
      <div className="w-full flex justify-center items-center space-x-4 py-10 md:py-10 sm:py-10 ">
        <img
          src={smartApp} // Add your iPhone image source here
          alt="iPhone displaying ReSound app"
        //   className="w-40 md:w-56  sm:w-56"
          className="w-56"
        />
        
      </div>
    </section>
  );
};

export default ResoundApp;
