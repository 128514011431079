import LayOut from "./layOut/LayOut";
import { ICONS } from "./utils/IMAGES";
import { FaWhatsapp, FaCalendar } from "react-icons/fa";

import AOS from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation (default: 400ms)
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  

  return (
    <div className=" relative">
      {/* Chat Icons  */}
      <div>
        <div>
          {" "}
          <a href='https://wa.me/212662423833' target="_blank" rel="noreferrer" className=" cursor-pointer duration-200 transition-all -right-[176px] hover:right-0 bg-custom-primary/80 py-2 px-2 w-[220px] justify-end   items-center text-white   top-[40%] fixed    z-20 flex  gap-2">
            <FaWhatsapp className=" absolute left-0 w-[20%] p-2  h-full text-[20px] bg-custom-primary" />{" "}
            Discutons
          </a>
        </div>
        <a href='/contactus' className=" cursor-pointer duration-200 transition-all -right-[176px] hover:right-0 bg-custom-primary/80 py-2 px-2 w-[220px] justify-end   items-center text-white  top-[47%] fixed   z-20 flex gap-2">
          <FaCalendar className=" absolute left-0 w-[20%] h-full p-2 text-[20px] bg-custom-primary" />{" "}
          Réservez maintenant
        </a>
      </div>
      <ScrollToTop />
      <LayOut />
    </div>
  );
}

export default App;
