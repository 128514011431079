import React from "react";
import Therapy from "../../assets/services/Therapy.jpg";
const Therapie = () => {
  return (
    <div>
      <div className=" flex gap-3 md:gap-[20px] lg:gap-[50px] flex-col md:flex-row items-center">
        <div className=" w-[100%] h-[250px] md:h-[300px] lg:h-[400px] relative md:w-[50%]">
          <img src={Therapy} alt="" className=" absolute w-full h-full object-cover" />
        </div>
        <div className=" w-[100%] md:w-[50%] pt-3">
          <div className=" flex flex-col gap-3">
            <h1 className="lg:text-[28px] text-custom-primary font-medium capitalize">
              Thérapie des acouphènes
            </h1>
            <div className=" flex flex-col gap-2">
              <h3 className=" text-custom-primary uppercase font-light  text-[14px] lg:text-[17px]">
                ÉTAPE 1
              </h3>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                Test auditif complet et évaluation des acouphènes (rendez-vous
                de 45 à 120 minutes). Cela comprend le test en cabine auditive
                et le remplissage de questionnaires appropriés
              </p>
            </div>
            <div className=" flex flex-col gap-2">
              <h3 className=" text-custom-primary uppercase font-light  text-[14px] lg:text-[17px]">
                ÉTAPE 2
              </h3>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                Discussion sur les options de traitement des acouphènes,
                notamment : la thérapie de réadaptation des acouphènes (TRT) et
                la thérapie cognitivo-comportementale (TCC)
              </p>
            </div>
            <div className=" flex flex-col gap-2">
              <h3 className=" text-custom-primary uppercase font-light  text-[14px] lg:text-[17px]">
                ÉTAPE 3
              </h3>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                Après avoir décidé quel itinéraire thérapeutique est le
                meilleur, six séances seront programmées pour chaque programme.
              </p>
            </div>
            <div className=" flex flex-col gap-2">
              <h3 className=" text-custom-primary uppercase font-light  text-[14px] lg:text-[17px]">
                ÉTAPE 4
              </h3>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                Des appareils auditifs et des instruments générateurs de bruit
                seront prescrits en fonction des besoins de chaque patient
              </p>
            </div>
            <div className=" flex flex-col gap-2">
              <h3 className=" text-custom-primary uppercase font-light  text-[14px] lg:text-[17px]">
                ÉTAPE 5
              </h3>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                Le patient sera libéré lorsque les acouphènes ne seront plus
                gênants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Therapie;
