import React, { useState, useEffect } from "react";
// import { Button } from "./Button/Button";
import Slider from "react-slick";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { HiOutlineXMark } from "react-icons/hi2";
import hearing_aids from "../utils/hearing-aids";
import { useParams } from "react-router-dom";
import { ButtonBanner } from "../components/Button/ButtonBanner";

// Icons
const Interton = () => {
  const { brand } = useParams();
  const [product, setProd] = useState([]);

  //  console.log(prod);
  useEffect(() => {
    const filtered = hearing_aids.find((item) => item.brand === brand);
    setProd(filtered); // Update filteredItems state with the filtered list
  }, [brand]);

  const [showModel, setShowModel] = useState(false);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: product.brand === "enzo" ? 2 : 3,
    slidesToScroll: product.brand === "enzo" ? 2 : 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsColors = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="px-2 sm:px-4 md:px-14 lg:px-20 xl:px-26 mt-6">
      <div className=" h-[700px] md:h-[640px] lg:h-[600px] w-full relative  overflow-hidden rounded-lg">
        <div className=" absolute bg-black/30 h-full w-full z-10 "></div>
        <img
          src={product?.imageBanner}
          alt=""
          className=" object-cover h-full w-full absolute "
        />
        <div className=" flex flex-col items-start lg:items-start gap-4 z-20   absolute left-1 md:left-10 l top-[60px] lg:top-[100px] text-white px-4">
          <h3 className="w-[300px] md:w-full font-bold text-[16px]">
            {product.mainTitle}
          </h3>
          <span className=" w-[300px] md:w-full xs:text-[1.5rem]  sm:text-[2rem] lg:text-[3rem] font-extralight  block leading-[40px] lg:leading-[55px]">
            {product?.subTitle}
          </span>
          <ul className=" w-full lg:w-[500px] flex flex-col gap-1 text-[15px] md:text-[16px] lg:text-[18px]">
            {product.desc?.map((item) => (
              <li className="md:text-base sm:text-base xs:text-xs lg:text-base">
                <span className=" text-custom-primary">&#10003;</span> {item}
              </li>
            ))}
          </ul>
          <div className=" flex flex-col lg:flex-row items-start lg:items-center  gap-4">
            <button onClick={() => setShowModel(true)}>
              <ButtonBanner
                textColor="text-white"
                text={product.buttonTwo}
                bgColor="bg-custom-primary"
              />
            </button>
          </div>
        </div>
      </div>
      {/* Start Video Player */}
      {showModel === true ? (
        <div className=" left-0 top-0 fixed w-full h-full bg-black/75 z-50">
          <span
            onClick={() => setShowModel(false)}
            className=" block absolute right-10 top-6 text-red-500 text-[30px] cursor-pointer"
          >
            <HiOutlineXMark />
          </span>
          <div className=" absolute  w-[90%] lg:w-[50%] h-[50%] top-0 bottom-0 left-0 right-0 m-auto">
            <Video
              autoPlay
              loop
              controls={["PlayPause", "Seek", "Time", "Volume", ""]}
              poster={product.imageBanner}
              onCanPlayThrough={() => {
                // Do stuff
              }}
            >
              <source src={product.video} type="video/mp4" />
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src="http://source.vtt"
                default
              />
            </Video>
          </div>
        </div>
      ) : null}
      {/* End Video Player */}

      <div>
        <h1 className=" w-fit m-auto font-light text-[1.4rem] lg:text-[2.6rem]  my-5 capitalize">
          {product.feature}
        </h1>

        <div className="text-center max-w-3xl mx-auto">
          <p className="text-lg text-gray-600">{product.descFeatures2}</p>
        </div>

        <div className={`my-10 slider-container`}>
          <Slider {...settings}>
            {product.featuresList?.map((item) => (
              <div className=" sliderCard flex flex-col gap-2 items-center">
                <img alt="" src={item.icon} />
                {/* {item.percent !== "" && <h2>{item.percent}</h2>} */}
                <h3 className=" text-[18px] text-center font-semibold">
                  {item.name}
                </h3>
                <h3 className=" text-[14px] text-center">{item.text}</h3>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className=" h-[700px] md:h-[640px] lg:h-[600px] w-full relative  overflow-hidden rounded-lg mb-10">
        <div className=" absolute bg-black/30 h-full w-full z-10 "></div>
        <img
          src={product.img_product}
          alt=""
          className=" object-cover h-full w-full absolute "
        />
      </div>

      <h1 className=" w-fit m-auto font-light text-[1.4rem] lg:text-[2.6rem]  my-5 capitalize">
      Modèles & accessoires
      </h1>
      <div className=" my-[100px] overflow-hidden colors ">
        {product.brand === "move" ? (
          <div className=" w-[80%] m-auto">
            <img src={product.listColors[0]} alt="" />
          </div>
        ) : (
          <Slider {...settingsColors}>
            {product.listColors?.map((item) => (
              <div className=" w-[80%] m-auto">
                <img src={item} alt="" />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default Interton;
