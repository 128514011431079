// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA-ZURaoTmJ9RHXqTDor9t2MJW0ei7B_bQ",
    authDomain: "dashboard-audis.firebaseapp.com",
    projectId: "dashboard-audis",
    storageBucket: "dashboard-audis.appspot.com",
    messagingSenderId: "474360559819",
    appId: "1:474360559819:web:83cf848526d3bfa1a4434e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const DB = getFirestore(app);