import React from "react";
import { accessories } from "../utils/dataProducts";
import { FaCircleChevronRight } from "react-icons/fa6";
import { PiCheckBold } from "react-icons/pi";

function Accessories() {
  return (
    <div className="px-6 sm:px-10 md:px-16 lg:px-28 my-16 max-w-4xl mx-auto">
      {accessories.map((item) => (
        <div className="flex flex-col md:flex-row gap-10 items-start py-8 border-b border-gray-300">
          {/* Image Section */}
          <div className="w-full md:w-1/3">
            <img
              className="object-cover w-full h-auto rounded-xl shadow-lg transition-transform transform hover:scale-105 duration-300"
              src={item.image}
              alt={item.name}
            />
          </div>

          {/* Text Content Section */}
          <div className="w-full md:w-2/3 space-y-4">
            <h1 className="text-[22px] text-custom-secondary font-semibold tracking-wide">
              {item.name}
            </h1>

            <ul className="list-none space-y-4">
              {item.checklist?.map((model, index) => (
                <li
                  className="flex items-start text-[16px] text-gray-700 leading-7"
                  key={index}
                >
                  <span className="text-custom-primary mr-3 text-[18px] font-semibold">
                    <PiCheckBold />
                  </span>
                  <span>{model}</span>
                </li>
              ))}
            </ul>

            {/* Download Links */}
            <div className="space-y-3 pt-4">
              <a
                className="text-custom-primary flex items-center gap-3 hover:gap-4 transition-all duration-300 text-[15px] font-medium underline"
                href={item.file[0]}
                target="_blank"
                download={item.file[0]}
                rel="noreferrer"
              >
                <FaCircleChevronRight className="text-[20px]" /> Télécharger la
                fiche technique
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accessories;
