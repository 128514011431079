import React from "react";
import ime from "../../assets/hearing Protection/iem.png";
import imeProduct from "../../assets/hearing Protection/iem1.png";

const IEM = () => {
  return (
    <div
      className=" flex flex-col gap-5 lg:gap-1 lg:flex-row"
      data-aos="fade-zoom-in"
    >
      <div className="  flex flex-col items-start lg:w-[50%]  gap-2">
        <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] w-[85%] ">
          <img className=" absolute w-full h-full  object-cover" src={ime} alt="" />
        </div>
        <div className=" relative h-[250px] md:h-[300px] lg:h-[500px]  w-[85%]">
          <img className=" absolute w-full h-full  object-cover" src={imeProduct} alt="" />
        </div>
      </div>

      <div className="lg:w-[50%] flex flex-col gap-6">
        <h2 className=" m-auto lg:m-0 capitalize lg:text-[25px] text-custom-primary">
        IEM (écouteurs intra-auriculaires)
        </h2>
        <p className=" text-[15px] text-[#5b636c] leading-8">
          64 Audio est un fabricant renommé d'écouteurs intra-auriculaires (IEM)
          très appréciés dans l'industrie audio. Ils se spécialisent dans la
          création d'IEM sur mesure qui offrent une qualité sonore et un confort
          exceptionnels. 64 Audio utilise des technologies et un savoir-faire de
          pointe pour produire des IEM adaptés aux besoins spécifiques des
          musiciens, des ingénieurs du son et des audiophiles. L'une des
          principales caractéristiques des IEM 64 Audio est leur conception sur
          mesure. Ils utilisent un processus appelé impression 3D pour créer des
          moules d'oreille personnalisés qui épousent parfaitement les contours
          des oreilles du porteur. Cela garantit un confort maximal et une
          excellente étanchéité, ce qui se traduit par une isolation sonore et
          une réponse des basses améliorées. Les IEM 64 Audio sont également
          connus pour leur qualité sonore exceptionnelle. Ils utilisent
          plusieurs haut-parleurs à armature équilibrée dans chaque écouteur,
          permettant une reproduction précise et exacte de l'audio sur
          l'ensemble du spectre de fréquences. Cela permet aux utilisateurs
          d'entendre des détails complexes, un équilibre tonal riche et une
          large scène sonore, ce qui les rend idéaux pour l'écoute critique et
          les applications professionnelles. De plus, 64 Audio propose une gamme
          de modèles avec différentes configurations de haut-parleurs et
          signatures sonores pour répondre à différentes préférences et
          applications. Ils offrent également des options de façades
          interchangeables, de sélections de câbles et d'illustrations
          personnalisées, permettant aux utilisateurs de personnaliser leurs IEM
          en fonction de leur style et de leurs préférences. Dans l'ensemble,
          les moniteurs intra-auriculaires 64 Audio sont très appréciés pour
          leur qualité sonore exceptionnelle, leur confort et leurs options de
          personnalisation. Ils sont appréciés par les professionnels, les
          artistes et les audiophiles, offrant une expérience d'écoute immersive
          et précise pour un large éventail d'applications.
        </p>
      </div>
    </div>
  );
};

export default IEM;
