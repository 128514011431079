import React from 'react'

const Title = ({title}) => {
  return (
    <div>
        <h1 className='py-[60px] w-fit m-auto  leading-10 text-[16px] md:text-[23px] text-custom-primary font-medium lg:text-[30px]'>{title}</h1>
    </div>
  )
}

export default Title