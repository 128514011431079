import React from 'react'

const TitleWithImage = ({title, image}) => {
    
  return (
    <div className={`${!image ? 'bg-custom-primary/15' : ''}  relative w-full`}>
       {
        image ?  <>
        <img src={image} alt='about' className=' z-0 flex items-center justify-center absolute h-full w-full object-cover'/>
        <div className=' absolute w-full h-full z-10  bg-black/60'></div>
        </>
        
        : ''
       }
        
        <h1 className={` text-center capitalize py-16 px-4 text-[16px] m-auto md:text-[22px] lg:text-[32px] font-semibold w-fit z-20 relative ${image ? 'text-white' : 'text-gray-600'}`}>{title}</h1>
    </div>
  )
}

export default TitleWithImage