import React, { useState } from "react";
import TitleWithImage from "../components/TitleWithImage";
import service from "../assets/services/service.jpg";
import SubTitle from "../components/SubTitle";
import EvaluationAudiologique from "../components/ServicesComponents/EvaluationAudiologique";
import Readaptation from "../components/ServicesComponents/Readaptation";
import Therapie from "../components/ServicesComponents/Therapie";
import Printing3D from "../components/ServicesComponents/Printing3D";
import Mesures from "../components/ServicesComponents/Mesures";
import TeleSante from "../components/ServicesComponents/TeleSante";



export const Services = () => {
  const [showService, setShowServices] = useState("Evaluation");
  return (
    <div>
      <TitleWithImage title="Nos services" image={service} />
      <SubTitle
        maintitle="À l'audience de Maison Paramed"
        subTitle={[
          "Utilisez les dernières technologies",
          "Offrir des services personnalisés",
        ]}
      />
      <div>
        <p className=" px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36 text-slate-600 text-[13px] md:text-[15px] my-6 lg:text-[16px] text-center">
          Votre chemin vers la récupération de votre audition et la reconnexion
          avec votre entourage est un voyage, et nous sommes à vos côtés pour
          vous accompagner à chaque étape. Pour atteindre vos objectifs, qu'ils
          soient professionnels, sociaux ou pédagogiques, nous assumons la
          responsabilité de vous y amener. L'audition est une expérience qui
          doit être agréable. D'où notre mission principale : Ensemble dans
          votre parcours auditif!
        </p>
        <div className="">
          <ul className=" flex flex-wrap justify-center  py-3 bg-custom-primary text-[12px] sm:text-[13px] md:text-[14px] lg:text-[15px] text-white my-4">
            <li
              onClick={() => setShowServices("Evaluation")}
              className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
                showService === "Evaluation"
                  ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                  : ""
              }`}
            >
              {" "}
              Évaluation audiologique
            </li>
            <li
              onClick={() => setShowServices("Rééducation")}
              className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
                showService === "Rééducation"
                  ? " text-custom-primary font-semibold bg-custom-third rounded-md"
                  : ""
              }`}
            >
              Rééducation adulte et pédiatrique
            </li>
            <li
              onClick={() => setShowServices("Thérapie")}
              className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
                showService === "Thérapie" ? " text-custom-primary font-semibold bg-custom-third rounded-md" : ""
              }`}
            >
              Thérapie des acouphènes
            </li>
          
            <li  onClick={() => setShowServices("Mesures")}
             className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
               showService === "Mesures" ? " text-custom-primary font-semibold bg-custom-third rounded-md" : ""
             }`}>
              Mesures de l'oreille réelle
            </li>
            <li  onClick={() => setShowServices("TeleSante")}
             className={`px-2 mx-3 py-2 w-fit   cursor-pointer ${
               showService === "TeleSante" ? " text-custom-primary font-semibold bg-custom-third rounded-md" : ""
             }`}>
              Télésanté
            </li>
          </ul>
        </div>
        <div className="px-4 sm:px-8 md:px-16  lg:px-24 xl:px-36 py-10">
          {showService === "Evaluation" ? <EvaluationAudiologique /> : ""}
          {showService === "Rééducation" ? <Readaptation /> : ""}
          {showService === "Thérapie" ?  <Therapie/> : ''}
          {showService === "Mesures" ?  <Mesures/> : ''}
          {showService === "TeleSante" ?  <TeleSante/> : ''}
        </div>
      </div>
    </div>
  );
};
