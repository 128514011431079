import React from "react";
import {Typewriter} from "react-simple-typewriter";

const SubTitle = ({ maintitle, subTitle }) => {

  return (
    <div className=" pt-[60px] pb-[20px] w-fit m-auto text-center">
      <h1 className=" leading-10 text-[24px] md:text-[26px] text-custom-primary font-medium lg:text-[40px]">
        {maintitle}
      </h1>
      <span className=" leading-10 text-[20px] md:text-[22px] text-custom-primary font-normal lg:text-[28px]">
          {/* Style will be inherited from the parent element */}
          <Typewriter
            words={subTitle}
            loop={5}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
         
        </span>
    </div>
  );
};

export default SubTitle;
