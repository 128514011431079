import React from "react";
import shooting from "../../assets/hearing Protection/shooting.jpg";
import shootingProd from "../../assets/hearing Protection/shooting-prod.gif";
const Shooting = () => {
  return (
    <div className=" flex flex-col gap-5 lg:gap-1 lg:flex-row" data-aos="fade-zoom-in"
   >
      <div className="  flex items-start lg:w-[50%]  gap-2">
        <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] w-[75%]">
          <img className=" absolute w-full h-full  object-cover" src={shooting} alt="" />
        </div>
        <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] flex items-center justify-center w-[20%]">
          <img className=" w-full h-[150px]  object-cover" src={shootingProd} alt="" />
        </div>
      </div>
      <div className="lg:w-[50%] flex flex-col gap-6">
        <h2 className=" m-auto lg:m-0 capitalize lg:text-[25px] text-custom-primary">
        Chasse et tir
        </h2>
        <p className=" text-[15px] text-[#5b636c] leading-8">
          La protection auditive pour la chasse et le tir est non seulement
          importante, mais elle doit être considérée comme un élément essentiel
          de l’équipement de tout tireur. Le bruit fort généré par les armes à
          feu peut avoir des effets graves et durables sur notre audition. Le
          son produit par un coup de feu peut atteindre des niveaux bien
          supérieurs à 140 décibels, ce qui est suffisant pour causer des
          dommages immédiats aux structures délicates de l’oreille.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8">
          Une exposition répétée à un bruit d’une telle intensité peut entraîner
          une perte auditive irréversible, des acouphènes (bourdonnements
          d’oreilles) et d’autres problèmes auditifs. Il est essentiel de
          comprendre que ces dommages sont cumulatifs, ce qui signifie que
          chaque coup de feu tiré sans protection auditive adéquate augmente le
          risque global. Que vous soyez un tireur professionnel ou un chasseur
          occasionnel, prendre des mesures proactives pour protéger votre
          audition doit être une priorité absolue.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8">
          Heureusement, il existe différents types de protections auditives
          spécialement conçues pour les activités de chasse et de tir.
        </p>
      </div>
    </div>
  );
};

export default Shooting;
