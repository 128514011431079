import React from "react";
import { FaChevronRight } from "react-icons/fa";
import masterCard from "../assets/masterCard.png";
import visa from "../assets/visaLogo.png";
import logo from "../assets/logo.png";
const Footer = () => {
  return (
    <footer className="pt-10 px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36 py-4 bg-custom-primary text-white">
      <div className=" flex-col md:flex-row gap-8 flex justify-between items-start w-full lg:gap-[60px]">
        <div className=" w-[100%] md:w-[50%] flex flex-col gap-5">
          <a href="/">
            <img
              className="w-[70px] md:w-[100px] lg:w-[150px] drop-shadow-lg"
              src={logo}
              alt=""
            />
          </a>
          <p className="text-white font-normal text-[14px]">
            Maison Paramed est une enseigne Marocaine spécialisée dans
            l’appareillage auditif. Partenaire exclusif des plus grandes marques
            mondiales GN ReSound et Interton, les centres auditifs Audis
            proposent un ensemble de prestations sur-mesures aux prix les plus
            bas du marché.
          </p>
          {/* <h3 className=" text-[14px] lg:text-[16px] uppercase">
            Nous acceptons
          </h3>
          <div className=" flex items-center gap-2">
            <img src={visa} alt="" className=" w-[50px]" />
            <img src={masterCard} alt="" className=" w-[50px]" />
          </div> */}
        </div>
        <div className=" w-[100%] md:w-[50%]">
          <h3 className=" text-[14px] lg:text-[16px] uppercase">
            Liens importants
          </h3>
          <ul className=" text-white text-[12.3px] lg:text-[14px] w-full flex flex-col">
            <li>
              <a
                href="/devices"
                className=" hover:text-custom-third duration-300 transition-all w-full items-center gap-2 py-4 border-b border-custom-third flex "
              >
                <FaChevronRight /> Compatibilité des aides auditives
              </a>
            </li>
            <li>
              <a
                href="/maison-paramed"
                className=" hover:text-custom-third duration-300 transition-all w-full items-center gap-2 py-4 border-b border-custom-third flex "
              >
                <FaChevronRight /> Pourquoi Maison Paramed
              </a>
            </li>
            <li>
              <a
                href="/hearing-solutions"
                className=" hover:text-custom-third duration-300 transition-all w-full items-center gap-2 py-4 border-b border-custom-third flex "
              >
                {" "}
                <FaChevronRight />
                Solutions auditives
              </a>
            </li>
            <li>
              <a
                href="/services"
                className=" hover:text-custom-third duration-300 transition-all w-full items-center gap-2 py-4 border-b border-custom-third flex "
              >
                {" "}
                <FaChevronRight /> Nos services
              </a>
            </li>
            <li>
              <a
                href="/contactus"
                className=" hover:text-custom-third duration-300 transition-all w-full items-center gap-2 py-4 border-b border-custom-third flex "
              >
                {" "}
                <FaChevronRight /> Nous contacter
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className=" mt-12 gap-2 flex-col flex items-center justify-between lg:flex-row">
        <ul className=" flex items-center gap-4 flex-wrap justify-center text-[10.3px] md:text-[12.3px]">
          <li className=" text-custom-third after:content-[''] after:h-4 after:absolute relative after:w-[.5px] after:bg-custom-third after:top-0 after:right-[-5px]">
            {" "}
            Copyright 2024{" "}
          </li>
          <li className=" text-custom-third after:content-[''] after:h-4 after:absolute relative after:w-[.5px] after:bg-custom-third after:top-0 after:right-[-5px]">
            Maison Paramed{" "}
          </li>
          {/* <li className=" text-custom-third after:content-[''] after:h-4 after:absolute relative after:w-[.5px] after:bg-custom-third after:top-0 after:right-[-5px]">Al-right-2ilal Houri Medical Equipment LLC</li> */}
          <li>
            <a className=" text-white font-normal" href="/">
              Powered by EvoMarket
            </a>
          </li>
        </ul>
        <ul className=" flex flex-wrap justify-center items-center gap-2 text-[12.3px]">
          <li className=" text-custom-third after:content-[''] after:h-4 after:absolute relative after:w-[.5px] after:bg-custom-third after:top-0 after:right-[-5px]">
            Ask Our Experts
          </li>
          <li>
            <a className=" text-white font-normal" href="/">
              {" "}
              +2125 37 83 12 03
            </a>
          </li>
          <li>
            <a className=" text-white font-normal" href="/">
              maisonparamed@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
