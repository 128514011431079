import React from "react";
import { FaChevronRight } from "react-icons/fa";

const Display = ({ item, color }) => {
  return (
    <div>
      <div
        className={` flex flex-col  ${
          item.reverse ? "md:flex-row" : " md:flex-row-reverse"
        } items-center h-[460px]`}
      >
        <div className=" lg:w-[50%] relative w-full h-full flex items-center justify-center">
          <img
            className="h-full w-full absolute"
            src={item.image.length === 2 ? item.image[0] : item.image}
            alt=""
          />
        </div>
        <div
          className={` py-6 md:py-2 lg:w-[50%] h-full flex  items-center  justify-center bg-[${color}]`}
        >
          <div className=" flex flex-col gap-3 items-center m-2 md:m-6 lg:m-20 justify-center">
            <img
              className=" w-[40px] md:w-[60px]  lg:w-[60px]"
              src={item.icon}
              alt=""
            />
            <h3 className=" text-custom-primary text-[17px] font-medium lg:text-[18px]">
              {item.title}
            </h3>
            <p className=" text-center text-[12px] lg:text-[16px] text-slate-500">
              {item.desc}
            </p>
            {item.show_button && (
              <a
                href={item.lien}
                className=" mt-4 leading-[23px] uppercase text-custom-primary font-normal text-[13px] lg:text-[15px] hover:text-custom-primary/50 duration-200 transition-all flex items-center gap-1"
              >
                Lire Plus <FaChevronRight />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Display;
