import nexia from "../assets/hearing-aids/nexia.jpg";
import ficheTechnique from "../assets/hearing-aids/fiche-technique-nexia.pdf";
import linxquattroficheTechnique from "../assets/hearing-aids/linx-quattro.pdf";
// import ficheTechniqueTransmitter from "../assets/hearing-aids/nexia-cross-transmetteur.pdf";
import microRIE60S from "../assets/hearing-aids/nexia products/microRIE60S.png";
import BTEPowerBTE from "../assets/hearing-aids/nexia products/BTEPowerBTE.png";
import Transmetteur from "../assets/hearing-aids/nexia products/Transmetteur.png";
import nexiaColor1 from "../assets/hearing-aids/nexia products/colors-nexia.png";
import nexiaColor2 from "../assets/hearing-aids/nexia products/colors-nexia2.png";
import ReSoundNexia from "../assets/hearing-aids/nexia products/ReSoundNexia.mp4";
import linxquattro from "../assets/hearing-aids/linx-quattro.jpg";
import ReSoundLiNXQuattro from "../assets/hearing-aids/linx-quattro/ReSoundLiNXQuattro.mp4";
import RITE61 from "../assets/hearing-aids/linx-quattro/RITE 61 rechargeable.png";
import RITE61Product from "../assets/hearing-aids/linx-quattro/RITE61.png";
import bte67 from "../assets/hearing-aids/linx-quattro/RS-MiniBTE-67.png";
import bte88 from "../assets/hearing-aids/linx-quattro/RS-BTE-88.png";
import NWCIC from "../assets/hearing-aids/linx-quattro/NW-CIC.png";
import ITCDW from "../assets/hearing-aids/linx-quattro/ITC-DW.png";
import ITEDW from "../assets/hearing-aids/linx-quattro/ITE-DW.png";
// import keyBanner from "../assets/hearing-aids/keybanner.jpg";
import keyBanner from "../assets/hearing-aids/key-products/keybanner.jpeg";
// key import
// import keyficheTechnique from "../assets/hearing-aids/fiche-technique-key.pdf";
import RIE61 from "../assets/hearing-aids/key-products/RIE61.png";
import RITE61Key from "../assets/hearing-aids/key-products/RITE 61.png";
import RITE62Key from "../assets/hearing-aids/key-products/RIE62.png";
import bte67key from "../assets/hearing-aids/key-products/BTE 67.png";
import bte77KEY from "../assets/hearing-aids/key-products/BTE77.png";
import bte88key from "../assets/hearing-aids/key-products/BTE88.png";
import BTE98Key from "../assets/hearing-aids/key-products/BTE98.png";
import cicKey from "../assets/hearing-aids/key-products/CIC.png";
import ITCKey from "../assets/hearing-aids/key-products/ITC.png";
import ITEKey from "../assets/hearing-aids/key-products/ITE.png";
import riecolor from "../assets/hearing-aids/key-products/RIEColors.png";
import bteColors from "../assets/hearing-aids/key-products/BTEColors.png";
import Personalise from "../assets/hearing-aids/key-products/Personalise.png";
import EnzoBanner from "../assets/hearing-aids/enzoBanner.jpg";
// import resoundCic from "../assets/productsImages/Resound OMNIA CIC.png";
// import CustomOmnia from "../assets/productsImages/Custom OMNIA rechargeable.png";
// import resoundOmnia from "../assets/productsImages/resound-omnia.png";
// import oneHearing from "../assets/productsImages/One-hearing.jpg";
// import key from "../assets/productsImages/key-uae.jpg";
// import Enzo from "../assets/productsImages/enzo-3d-hearing.jpg";
import assistiveListrning from "../assets/productsImages/assist-listening-devices.jpg";
import streaming from "../assets/productsImages/streaming-sound-hear-aid.jpg";
import swim from "../assets/productsImages/ear-protections-uae.jpg";
import customhearingProtection from "../assets/productsImages/custom-made-hearing-protection-ear-uae-.jpg";

// enzo
import enzoColors from "../assets/hearing-aids/enzo-products/enzocolors.png";

// hs-thumbnails 

import hs_enzo from '../assets/hearing-aids/hs-enzo.png';
import hs_linx from '../assets/hearing-aids/hs-linx.png';
import hs_key from '../assets/hearing-aids/hs-key.png';
import hs_nexia from '../assets/hearing-aids/hs-nexia.png';

// Nexia Pdfs
import crostransmetteur from '../assets/hearing-aids/nexia products/crostransmetteure.pdf';
import transCros1and2 from '../assets/hearing-aids/nexia products/transCros1and2.pdf';
import slider3pdf1 from '../assets/hearing-aids/nexia products/slider3pdf1.pdf'
import slider3pdf2 from '../assets/hearing-aids/nexia products/slider3pdf2.pdf'
import slider3pdf3 from '../assets/hearing-aids/nexia products/slider3pdf3.pdf'
import slider4pdf1 from '../assets/hearing-aids/nexia products/slider4pdf1.pdf'
import slider4pdf2 from '../assets/hearing-aids/nexia products/slider4pdf2.pdf'
import slider4pdf3 from '../assets/hearing-aids/nexia products/slider4pdf3.pdf'
// Nexia Images 

import sliderimg1 from '../assets/hearing-aids/nexia products/slider3img1.png';
import sliderimg2 from '../assets/hearing-aids/nexia products/slider3img2.png';
import sliderimg3 from '../assets/hearing-aids/nexia products/slider3img3.png';
import slider4img1 from '../assets/hearing-aids/nexia products/slider4img1.png';
import slider4img2 from '../assets/hearing-aids/nexia products/slider4img2.png';
import slider4img3 from '../assets/hearing-aids/nexia products/slider4img3.png';


// Linx Pdfs 

import slider2pdf1 from '../assets/hearing-aids/linx-quattro/slider2pdf1.pdf';
import slider2pdf2 from '../assets/hearing-aids/linx-quattro/slider2pdf2.pdf';
import slider2pdf3 from '../assets/hearing-aids/linx-quattro/slider2pdf3.pdf';

import linxslider3pdf1 from '../assets/hearing-aids/linx-quattro/linxslider3pdf1.pdf';
import linxslider3pdf2 from '../assets/hearing-aids/linx-quattro/linxslider3pdf2.pdf';
import linxslider3pdf3 from '../assets/hearing-aids/linx-quattro/linxslider3pdf3.pdf';

// Linx Color Options 
import coloroptionsimg1 from '../assets/hearing-aids/linx-quattro/coloroptionsimg1.png';
import coloroptionsimg2 from '../assets/hearing-aids/linx-quattro/coloroptionsimg2.png';

// Enzo Video 
import enzoVideo from '../assets/hearing-aids/enzo-products/enzoVideo.mp4';

// Enzo Images
import enzoimg1 from '../assets/hearing-aids/enzo-products/enzoimg1.png';
import enzoimg2 from '../assets/hearing-aids/enzo-products/enzoimg2.png';

// Enzo PDF

import enzopdf1 from '../assets/hearing-aids/enzo-products/enzopdf1.pdf';
import enzopdf2 from '../assets/hearing-aids/enzo-products/enzopdf2.pdf';


// Key Video 
import keyVideo from '../assets/hearing-aids/key-products/keyVideo.mp4';


// Key Pdfs
import keyslider1pdf1 from '../assets/hearing-aids/key-products/keyslider1pdf1.pdf';
import keyslider2pdf1 from '../assets/hearing-aids/key-products/keyslider2pdf1.pdf';
import keyslider2pdf2 from '../assets/hearing-aids/key-products/keyslider2pdf2.pdf';
import keyslider2pdf3 from '../assets/hearing-aids/key-products/keyslider2pdf3.pdf';
import keyslider3pdf1 from '../assets/hearing-aids/key-products/keyslider3pdf1.pdf';
import keyslider3pdf2 from '../assets/hearing-aids/key-products/keyslider3pdf2.pdf';
import keyslider3pdf3 from '../assets/hearing-aids/key-products/keyslider3pdf3.pdf';
import keyslider4pdf1 from '../assets/hearing-aids/key-products/keyslider4pdf1.pdf';


// Interton Ready
import ready from '../assets/hearing-aids/interton/ready.jpg';
import readybanner from '../assets/hearing-aids/interton/readybanner.jpg';
import readyVideo from '../assets/hearing-aids/interton/readyVideo.mp4';
import readyicon1 from '../assets/hearing-aids/interton/icons/icon1.png';
import readyicon2 from '../assets/hearing-aids/interton/icons/icon2.png';
import readyicon3 from '../assets/hearing-aids/interton/icons/icon3.png';
import readyicon4 from '../assets/hearing-aids/interton/icons/icon4.png';
import readyProduct from '../assets/hearing-aids/interton/readyProduct.jpg';

// Interton Move

import move from '../assets/hearing-aids/interton/moveAids.png'
import moveBanner from '../assets/hearing-aids/interton/moveBanner.jpg'
import moveBanner2 from '../assets/hearing-aids/interton/moveBanner2.jpg'
import moveVideo from '../assets/hearing-aids/interton/moveVideo.mp4'

// Pile Auditive
import pile10 from '../assets/accessories/pile2.jpg'
import pile675 from '../assets/accessories/pile1.jpg'
import pile13 from '../assets/accessories/pile3.jpg'
const hearing_aids = [

  {
    id: 5,
    // image: key,
    gamme: "resound",
    image: hs_key,
    category: "Aides Auditives",
    level: "PREMIUM",
    // desc: "Des appareils auditifs essentiels modernes et faciles à utiliser avec un son exceptionnel pour la vie quotidienne.",
    // desc:"Des aides auditives enrichissantes pour les pertes auditives sévères à profondes avec un son clair, confortable et de haute qualité.",
    mainTitle: "ReSound Key",
    subTitle: "Tout le monde mérite une excellente audition",
    brand: "resound-key",
    desc: [
      "Chez ReSound, nous pensons que tout le monde mérite une expérience auditive exceptionnelle et individualisée. Vous pouvez maintenant accueillir encore plus de clients dans votre clinique pour atteindre cet objectif.",
    ],
    imageBanner: keyBanner,
    feature: "Caractéristiques Key",
    buttonOne: "Commander ReSound Key",
    buttonTwo: "Pourquoi choisir ReSound Key",
    video: keyVideo,
    featuresList: [
      {
        id: 1,
        name: "Son clair et fonctions audiologiques haut de gamme",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/47b32e66a51649fb8e44ad5f11e22e72.ashx",
      },
      {
        id: 2,
        name: "Rechargeabilité facile avec jusqu’à 30 heures de charge",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/cfc5009a55014afb93d19cb56ac9ade8.ashx",
      },
      {
        id: 3,
        name: "Connectivité avec Bluetooth® Low Energy",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/31e66d4c4a7941ef9766afe0636a2c13.ashx",
      },
      {
        id: 4,
        name: "Transmission audio directe depuis les appareils iOS et Android",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/31e66d4c4a7941ef9766afe0636a2c13.ashx",
      },

    ],
    listColors: [riecolor, bteColors, Personalise],
    listProducts: [
      {
        nameProduct: "RIE 61 rechargeable",
        modele: "",
        checklist: [
          "Batterie Lithium-ion rechargeable",
          "Directivité Natural Directionality II",
          "Synchronised Soft Switching",
          "Mode Musique",
          "Niveaux de puissance des écouteurs : LP, MP, HP, UP",
        ],
        ficheTechnique: keyslider1pdf1,
        imageProduct: RIE61,
      },
      {
        nameProduct: "RITE 61",
        modele: "",
        checklist: [
          "Taille de la pile : 312 Zinc-Air",
          'Directivité adaptative à faisceau variable "MultiScope"',
          "Soft Switching",
          'Adaptation progressive des gains "Synchronized Acceptance Manager"',
          "Niveaux de puissance des écouteurs : LP, MP, HP, UP",
        ],
        ficheTechnique: keyslider1pdf1,
        imageProduct: RITE61Key,
      },
      {
        nameProduct: "RIE 62",
        modele: "",
        checklist: [
          "Taille de la pile : 13 zinc-air",
          "Synchronised Soft Switching",
          "Directivité Natural Directionality II",
          "DFS Ultra II",
          "Noise Tracker II",
          "Bobine T, entrée audio DAI",
          "Niveaux de puissance des écouteurs : LP, MP, HP, UP",
        ],
        ficheTechnique: keyslider1pdf1,
        imageProduct: RITE62Key,
      },
      {
        nameProduct: "BTE 67",
        modele: "",
        checklist: [
          "Taille de la pile : 312 Zinc-Air",
          "Synchronised Soft Switching",
          "Directivité Natural Directionality II",
          "DFS Ultra II",
          "Noise Tracker II",
          "Diffusion directe iOS et Android TM",
          "Bobine T, entrée audio DAI",
        ],
        ficheTechnique: keyslider2pdf1,
        imageProduct: bte67key,
      },
      {
        nameProduct: "BTE 77",
        modele: "",
        checklist: [
          "Taille de la pile : 13 zinc-air",
          "Synchronised Soft Switching",
          "Directivité Natural Directionality II",
          "DFS Ultra II",
          "Noise Tracker II",
          "Bobine T, entrée audio DAI",
        ],
        ficheTechnique: keyslider2pdf2,
        imageProduct: bte77KEY,
      },
      {
        nameProduct: "BTE 88",
        modele: "",
        checklist: [
          "Taille de la pile : 13 zinc-air",
          "Synchronised Soft Switching",
          "Directivité Natural Directionality II",
          "DFS Ultra II",
          "Noise Tracker II",
          "Bobine T, entrée audio DAI",
        ],
        ficheTechnique: keyslider2pdf3,
        imageProduct: bte88key,
      },
      {
        nameProduct: "BTE 98",
        modele: "",
        checklist: [
          "Taille de la pile : 675 zinc-air",
          "Synchronised Soft Switching",
          "Directivité Natural Directionality II",
          "DFS Ultra II",
          "Noise Tracker II",
          "Bobine T, entrée audio DAI",
        ],
        ficheTechnique: keyslider3pdf1,
        imageProduct: BTE98Key,
      },
      {
        nameProduct: "CIC",
        modele: "",
        checklist: [
          "Taille de la pile : 10A Zinc-Air",
          "Environmenttal Classifier",
          "DFS Ultra II",
          "Noise Tracker II",
          "Niveaux de puissance : LP, MP & HP",
        ],
        ficheTechnique: keyslider3pdf2,
        imageProduct: cicKey,
      },
      {
        nameProduct: "ITC",
        modele: "",
        checklist: [
          "Taille de la pile : 312 Zinc-Air",
          "Soft Switching",
          "Directivité Natural Directionality II ",
          "DFS Ultra II",
          "Noise Tracker II",
          "Bobine T",
          "Niveaux de puissance :MP, HP & UP",
        ],
        ficheTechnique: keyslider3pdf3,
        imageProduct: ITCKey,
      },
      {
        nameProduct: "ITE",
        modele: "",
        checklist: [
          "Taille de la pile : 312/13 Zinc-Air",
          "Bouton sélecteur de programme, commande de volume, bobine T",
          "Synchronised Soft Switching",
          "Directivité Natural Directionality II ",
          "DFS Ultra II",
          "Noise Tracker II",
          "Niveaux de puissance :MP, HP & UP",
        ],
        ficheTechnique: keyslider4pdf1,
        imageProduct: ITEKey,
      },
    ],
  },
  {
    id: 6,
    // image: Enzo,
    gamme: "resound",
    image: hs_enzo,
    level: "PREMIUM",
    category: "Aides Auditives",
    mainTitle: "ReSound ENZO Q",
    subTitle: "Tellement plus que puissant",
    brand: "enzo",
    desc: [
      "Un son cristallin, confortable et de haute qualité",
      "Une connectivité inégalée et une diffusion directe",
      "Durée de vie des piles jusqu'à 20% plus longue",
      "Entièrement personnalisable en fonction des préférences de vos clients",
      "Assistance pratique à distance et en temps réel",
      "Complète parfaitement les implants CochlearTM",
    ],
    imageBanner: EnzoBanner,
    feature: "Caractéristiques Enzo",
    buttonOne: "Commander ReSound Enzo",
    buttonTwo: "Pourquoi choisir ReSound Enzo",
    video: enzoVideo,
    featuresList: [
      {
        id: 1,
        name: "Un son cristallin, confortable et de haute qualité",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/47b32e66a51649fb8e44ad5f11e22e72.ashx",
      },
      {
        id: 2,
        name: "Une bonne écoute partout",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/cfc5009a55014afb93d19cb56ac9ade8.ashx",
      },
      {
        id: 3,
        name: "Un son confortable",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/31e66d4c4a7941ef9766afe0636a2c13.ashx",
      },
    ],
    listColors: [enzoColors],
    listProducts: [
      {
        nameProduct: "BTE 88 - Haute puissance",
        modele: "",
        checklist: [
          "Taille de la pile: 13",
          "Disponible en standard avec les fonctions bobine T et Direct Audio Input (DAI)",
          "Gamme complète d'accessoires sans fil",
        ],
        ficheTechnique: enzopdf1, // Update with actual file path
        imageProduct: enzoimg1, // Update with actual image path
      },
      {
        nameProduct: "BTE 98 - Super Power",
        modele: "",
        checklist: [
          "Taille de la pile: 675",
          "Disponible en standard avec les fonctions bobine T et Direct Audio Input (DAI)",
          "Gamme complète d'accessoires sans fil",
        ],
        ficheTechnique: enzopdf2, // Update with actual file path
        imageProduct: enzoimg2, // Update with actual image path
      }
    ],
  },
  {
    gamme: "resound",
    mainTitle: "ReSound LiNX Quattro",
    subTitle:
      "Une collection complète d'aides auditives pour répondre à tous les besoins auditifs",
    category: "Aides Auditives",
    brand: "linx-quattro",
    // image: linxquattro,
    image: hs_linx,
    level: "PREMIUM",
    desc: [
      "Une expérience sonore brillante avec Layers of Sound",
      "Streaming direct disponible sur toute la gamme depuis des appareils iOS et Android™",
      "Une assistance entièrement personnalisable, à distance et en temps réel",
      "La solution rechargeable avancée",
    ],
    imageBanner: linxquattro,
    feature: "Caractéristiques Linx-quattro ",
    buttonOne: "Commander ReSound linx-quattro",
    buttonTwo: "Pourquoi choisir ReSound linx-quattro",
    video: ReSoundLiNXQuattro,
    featuresList: [
      {
        id: 1,
        name: "Expérience sonore limpide ",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/47b32e66a51649fb8e44ad5f11e22e72.ashx",
      },
      {
        id: 2,
        name: "Un streaming complet",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/cfc5009a55014afb93d19cb56ac9ade8.ashx",
      },
      {
        id: 3,
        name: "Une solution rechargeable avancée",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/31e66d4c4a7941ef9766afe0636a2c13.ashx",
      },
      {
        id: 4,
        name: "Des intras sans compromis ",
        percent: "",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/47b32e66a51649fb8e44ad5f11e22e72.ashx",
      },
    ],
    listColors: [coloroptionsimg1, coloroptionsimg2],
    listProducts: [
      {
        nameProduct: "RITE 61 rechargeable",
        modele: "",
        checklist: [
          "Rechargeabilité lithium-ion",
          "Diffusion directe iOS et Android",
          "Niveaux de puissance des écouteurs : LP, MP, HP et UP",
        ],
        ficheTechnique: linxquattroficheTechnique,
        imageProduct: RITE61,
      },
      {
        nameProduct: "RITE 61",
        modele: "",
        checklist: [
          "Pile : 312",
          "Diffusion directe iOS et Android TM",
          "Niveaux de puissance des écouteurs : LP, MP, HP et UP",
        ],
        ficheTechnique: linxquattroficheTechnique,
        imageProduct: RITE61Product,
      },
      {
        nameProduct: "RIE 62",
        modele: "NX61-DRW",
        checklist: [
          "Pile : 312",
          "Diffusion directe iOS et Android TM",
          "Niveaux de puissance des écouteurs : LP, MP, HP et UP",
        ],
        ficheTechnique: linxquattroficheTechnique,
        imageProduct: RITE61Product,
      },
      {
        nameProduct: "BTE 67",
        modele: "",
        checklist: [
          "Pile : 312",
          "Diffusion directe iOS et Android TM",
          "Disponible avec tube fin et coude et standard avec bobine T",
        ],
        ficheTechnique: slider2pdf1,
        imageProduct: bte67,
      },
      {
        nameProduct: "BTE 77",
        modele: "",
        checklist: [
          "Pile : 312",
          "Diffusion directe iOS et Android TM",
          "Disponible avec tube fin et coude et standard avec bobine T et entrée audio DAI",
        ],
        ficheTechnique: slider2pdf2,
        imageProduct: bte67,
      },
      {
        nameProduct: "BTE 88",
        modele: "",
        checklist: [
          "Pile : 312",
          "Diffusion directe iOS et Android TM",
          "Disponible avec tube fin et coude et standard avec bobine T et entrée audio DAI",
        ],
        ficheTechnique: slider2pdf3,
        imageProduct: bte88,
      },
      {
        nameProduct: "NW-CIC",
        modele: "",
        checklist: [
          "Pile : 10",
          "Niveaux de puissance des écouteur : LP, MP, HP, UP",
          "Gamme complète d'accessoires sans fil",
        ],
        ficheTechnique: linxslider3pdf1,
        imageProduct: NWCIC,
      },
      {
        nameProduct: "ITC-DW",
        modele: "",
        checklist: [
          "Pile : 312",
          "Niveaux de puissance des écouteur : LP, MP, HP, UP",
          "Gamme complète d'accessoires sans fil",
        ],
        ficheTechnique: linxslider3pdf2,
        imageProduct: ITCDW,
      },
      {
        nameProduct: "ITE-DW",
        modele: "",
        checklist: [
          "Pile : 312/13",
          "Niveaux de puissance des écouteur : MP, HP, UP",
          "Gamme complète d'accessoires sans fil",
        ],
        ficheTechnique: linxslider3pdf3,
        imageProduct: ITEDW,
      },
    ],
  },
  {
    gamme: "resound",
    mainTitle: "ReSound Nexia - Pour les pertes auditives légères à profondes",
    subTitle: "Une ère nouvelle pour l'audition",
    brand: "nexia",
    category: "Aides Auditives",
    // image: nexia,
    image: hs_nexia,
    level: "PREMIUM",
    desc: [
      "Conçu pour Auracast™.",
      " Très bien notée pour l'écoute dans le bruit.",
      " Disponible dans tous les modèles.",
    ],
    imageBanner: nexia,
    feature: "Caractéristiques Nexia",
    buttonOne: "Commander ReSound Nexia",
    buttonTwo: "Pourquoi choisir ReSound Nexia",
    video: ReSoundNexia,
    featuresList: [
      {
        id: 1,
        name: "d'amélioration de la compréhension de la parole dans le bruit",
        percent: "150%",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/47b32e66a51649fb8e44ad5f11e22e72.ashx",
      },
      {
        id: 2,
        name: "Les utilisateurs recommandent ReSound Nexia™ à 89% pour l'écoute dans le bruit",
        percent: "89%",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/cfc5009a55014afb93d19cb56ac9ade8.ashx",
      },
      {
        id: 3,
        name: "50% de satisfaction en plus pour les utilisateurs de ReSound Nexia",
        percent: "50%",
        icon: "https://pro.resound.com/fr-fr/products/hearing-aids/-/media/31e66d4c4a7941ef9766afe0636a2c13.ashx",
      },
    ],
    listColors: [nexiaColor1, nexiaColor2],
    listProducts: [
      {
        nameProduct: "microRIE 60S Rechargeable",
        modele: "NX60S-DRWC",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Batterie rechargeable Li-Ion",
          "TapControl",
          "Bouton poussoir",
          "Chargeur Premium ou standard",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "RIE 61",
        modele: "NX61-DRW",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton poussoir",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "RIE 61",
        modele: "NX61-DRW",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton Multi-Fonction",
          "Bobine T",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "CROS Transmetteur",
        modele: "CX160S-DRWC",
        checklist: [
          "Écouteurs LP, MP & HP",
          "8 couleurs",
          "Batterie rechargeable Li-ion",
          "Bouton poussoir",
          "Chargeur Premium",
        ],
        ficheTechnique: crostransmetteur,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "BTE/PowerBTE Rechargeable",
        modele: "NX77-DWC, NX88-DWC",
        checklist: [
          "Modèles BTE et PBTE ",
          "8 couleurs",
          "Batterie rechargeable Li-ion",
          "Bouton poussoir",
          "Bobine T",
          "Option DAI pour PowerBTE",
          "Chargeur de bureau",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: transCros1and2,
        imageProduct: BTEPowerBTE,
      },
      {
        nameProduct: "Transmetteur CROS BTE",
        modele: "Modèle 70",
        checklist: [
          "Transmetteur sans fil BTE qui se connecte aux BTE et intras rechargeables",
          "8 couleurs",
          "Batterie rechargeable au lithium-ion pour une durée de vie prolongée",
          "Bouton poussoir",
        ],
        ficheTechnique: transCros1and2,
        imageProduct: Transmetteur,
      },
      {
        nameProduct: "ITC Sans fil",
        modele: "ITC 312-WL",
        checklist: [
          "Options LP, MP, HP & UP",
          "4 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton-poussoir programmable et contrôle du volume",
          "Multi-Mic+, TV-Streamer+, TV-Streamer 2, Remote Control, Micro Mic et Multi Mic",
        ],
        ficheTechnique: slider3pdf1,
        imageProduct: sliderimg1, // Update with actual image path
      },
      {
        nameProduct: "ITC/ITE Rechargeable",
        modele: "NXITC-DWC, NXITE-DWC",
        checklist: [
          "LP, MP, HP & UP",
          "4 couleurs",
          "Batterie rechargeable Li-Ion",
          "Chargeur intra",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2, Phone Clip+, Micro Mic et Multi Mic",
        ],
        ficheTechnique: slider3pdf2, // You can merge ITC/ITE fiche technique if it's the same
        imageProduct: sliderimg2, // Update with actual image path
      },
      {
        nameProduct: "CIC",
        modele: "NX-CIC",
        checklist: [
          "LP, MP & HP",
          "4 couleurs",
          "Pile 10A Zinc-Air",
        ],
        ficheTechnique: slider3pdf3,
        imageProduct: sliderimg3, // Update with actual image path

      },
      {
        nameProduct: "Multi-Mic+",
        modele: "",
        checklist: [
          "Connexion via Auracast",
          "Rechargeable",
          "Double microphone pour capter la voix",
          "Connecteur FM",
          "Bobine téléphonique intégrée",
          "Connecteur mini-jack",
          "Bouton poussoir dans l'application",
        ],
        ficheTechnique: slider4pdf1, // Update with actual file path
        imageProduct: slider4img1, // Update with actual image path
      },
      {
        nameProduct: "TV-Streamer+",
        modele: "",
        checklist: [
          "Prêt pour Bluetooth Auracast",
          "Qualité sonore supérieure et consommation de batterie nettement inférieure",
          "Appairage facile aux aides auditives et à la télévision",
        ],
        ficheTechnique: slider4pdf2, // Update with actual file path
        imageProduct: slider4img2, // Update with actual image path
      },
      {
        nameProduct: "Chargeur Premium",
        modele: "",
        checklist: [
          "Dimensions: 99,4 x 35 x 67,5 mm",
          "Poids: 145 grammes",
          "Batterie rechargeable Li-Ion, 3,6V, 2600 mAh",
          "Min. 3 charges complètes de deux aides auditives, sans aides auditives: 12 mois",
          "Temps de charge de la batterie Lithium-Ion interne du chargeur: Max 3,5 heures",
        ],
        ficheTechnique: slider4pdf3, // Update with actual file path
        imageProduct: slider4img3, // Update with actual image path
      }
    ],
  },
  {
    gamme: "interton",
    mainTitle: "Interton Ready",
    subTitle: "Aides auditives sans fil",
    brand: "ready",
    category: "Aides Auditives",
    // image: nexia,
    image: ready,
    level: "PREMIUM",
    desc: [
      "Très bon son même dans les situations bruyantes",
      " Une qualité qui dure",
      " Un bon rapport qualité/prix ",
    ],
    imageBanner: readybanner,
    feature: "Caractéristiques Ready",
    buttonOne: "Commander",
    buttonTwo: "Pourquoi choisir Interton Ready",
    video: readyVideo,
    descFeatures1: "Diffusion du son directement dans votre aide auditive",
    descFeatures2: "Grâce à nos accessoires sans fil, vous pouvez également utiliser vos aides auditives Interton pour diffuser du son et des divertissements sans fil.",
    featuresList: [

      {
        id: 1,
        name: "Transmettre le son de la TV",
        percent: "100%",
        text: "Transmettez le son stéréo directement à vos appareils auditifs",
        icon: readyicon1, // Replace with the correct icon path
      },
      {
        id: 2,
        name: "Profitez des conversations",
        percent: "100%",
        text: "Transmettez les voix à vos appareils auditifs",
        icon: readyicon2, // Replace with the correct icon path
      },
      {
        id: 3,
        name: "Profitez des appels téléphoniques",
        percent: "100%",
        text: "Transmettez les appels téléphoniques à vos appareils auditifs",
        icon: readyicon3, // Replace with the correct icon path
      },
      {
        id: 4,
        name: "Contrôlez vos appareils auditifs",
        percent: "100%",
        text: "Réglez le volume de vos appareils auditifs et plus encore",
        icon: readyicon4, // Replace with the correct icon path
      },


    ],
    listColors: [nexiaColor1, nexiaColor2],
    listProducts: [
      {
        nameProduct: "microRIE 60S Rechargeable",
        modele: "NX60S-DRWC",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Batterie rechargeable Li-Ion",
          "TapControl",
          "Bouton poussoir",
          "Chargeur Premium ou standard",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "RIE 61",
        modele: "NX61-DRW",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton poussoir",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "RIE 61",
        modele: "NX61-DRW",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton Multi-Fonction",
          "Bobine T",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "CROS Transmetteur",
        modele: "CX160S-DRWC",
        checklist: [
          "Écouteurs LP, MP & HP",
          "8 couleurs",
          "Batterie rechargeable Li-ion",
          "Bouton poussoir",
          "Chargeur Premium",
        ],
        ficheTechnique: crostransmetteur,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "BTE/PowerBTE Rechargeable",
        modele: "NX77-DWC, NX88-DWC",
        checklist: [
          "Modèles BTE et PBTE ",
          "8 couleurs",
          "Batterie rechargeable Li-ion",
          "Bouton poussoir",
          "Bobine T",
          "Option DAI pour PowerBTE",
          "Chargeur de bureau",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: transCros1and2,
        imageProduct: BTEPowerBTE,
      },
      {
        nameProduct: "Transmetteur CROS BTE",
        modele: "Modèle 70",
        checklist: [
          "Transmetteur sans fil BTE qui se connecte aux BTE et intras rechargeables",
          "8 couleurs",
          "Batterie rechargeable au lithium-ion pour une durée de vie prolongée",
          "Bouton poussoir",
        ],
        ficheTechnique: transCros1and2,
        imageProduct: Transmetteur,
      },
      {
        nameProduct: "ITC Sans fil",
        modele: "ITC 312-WL",
        checklist: [
          "Options LP, MP, HP & UP",
          "4 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton-poussoir programmable et contrôle du volume",
          "Multi-Mic+, TV-Streamer+, TV-Streamer 2, Remote Control, Micro Mic et Multi Mic",
        ],
        ficheTechnique: slider3pdf1,
        imageProduct: sliderimg1, // Update with actual image path
      },
      {
        nameProduct: "ITC/ITE Rechargeable",
        modele: "NXITC-DWC, NXITE-DWC",
        checklist: [
          "LP, MP, HP & UP",
          "4 couleurs",
          "Batterie rechargeable Li-Ion",
          "Chargeur intra",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2, Phone Clip+, Micro Mic et Multi Mic",
        ],
        ficheTechnique: slider3pdf2, // You can merge ITC/ITE fiche technique if it's the same
        imageProduct: sliderimg2, // Update with actual image path
      },
      {
        nameProduct: "CIC",
        modele: "NX-CIC",
        checklist: [
          "LP, MP & HP",
          "4 couleurs",
          "Pile 10A Zinc-Air",
        ],
        ficheTechnique: slider3pdf3,
        imageProduct: sliderimg3, // Update with actual image path

      },
      {
        nameProduct: "Multi-Mic+",
        modele: "",
        checklist: [
          "Connexion via Auracast",
          "Rechargeable",
          "Double microphone pour capter la voix",
          "Connecteur FM",
          "Bobine téléphonique intégrée",
          "Connecteur mini-jack",
          "Bouton poussoir dans l'application",
        ],
        ficheTechnique: slider4pdf1, // Update with actual file path
        imageProduct: slider4img1, // Update with actual image path
      },
      {
        nameProduct: "TV-Streamer+",
        modele: "",
        checklist: [
          "Prêt pour Bluetooth Auracast",
          "Qualité sonore supérieure et consommation de batterie nettement inférieure",
          "Appairage facile aux aides auditives et à la télévision",
        ],
        ficheTechnique: slider4pdf2, // Update with actual file path
        imageProduct: slider4img2, // Update with actual image path
      },
      {
        nameProduct: "Chargeur Premium",
        modele: "",
        checklist: [
          "Dimensions: 99,4 x 35 x 67,5 mm",
          "Poids: 145 grammes",
          "Batterie rechargeable Li-Ion, 3,6V, 2600 mAh",
          "Min. 3 charges complètes de deux aides auditives, sans aides auditives: 12 mois",
          "Temps de charge de la batterie Lithium-Ion interne du chargeur: Max 3,5 heures",
        ],
        ficheTechnique: slider4pdf3, // Update with actual file path
        imageProduct: slider4img3, // Update with actual image path
      }
    ],
    img_product: readyProduct,

  },
  {
    gamme: "interton",
    mainTitle: "Interton Move",
    subTitle: "Notre nouvelle aide auditive intelligente a été créée pour les personnes qui veulent profiter au maximum de la vie. ",
    brand: "move",
    category: "Aides Auditives",
    // image: nexia,
    image: move,
    level: "PREMIUM",
    desc: [
      "Excellente qualité sonore",
      " Solution rechargeable ",
      "Possibilités de streaming ",
    ],
    imageBanner: moveBanner,
    feature: "Caractéristiques Move",
    buttonOne: "Commander",
    buttonTwo: "Pourquoi choisir Interton Move",
    video: moveVideo,
    descFeatures1: "Diffusion du son directement dans votre aide auditive",
    descFeatures2: "Grâce à nos accessoires sans fil, vous pouvez également utiliser vos aides auditives Interton pour diffuser du son et des divertissements sans fil.",
    featuresList: [

      {
        id: 1,
        name: "Transmettre le son de la TV",
        percent: "100%",
        text: "Transmettez le son stéréo directement à vos appareils auditifs",
        icon: readyicon1, // Replace with the correct icon path
      },
      {
        id: 2,
        name: "Profitez des conversations",
        percent: "100%",
        text: "Transmettez les voix à vos appareils auditifs",
        icon: readyicon2, // Replace with the correct icon path
      },
      {
        id: 3,
        name: "Profitez des appels téléphoniques",
        percent: "100%",
        text: "Transmettez les appels téléphoniques à vos appareils auditifs",
        icon: readyicon3, // Replace with the correct icon path
      },
      {
        id: 4,
        name: "Contrôlez vos appareils auditifs",
        percent: "100%",
        text: "Réglez le volume de vos appareils auditifs et plus encore",
        icon: readyicon4, // Replace with the correct icon path
      },


    ],
    listColors: [move],
    listProducts: [
      {
        nameProduct: "microRIE 60S Rechargeable",
        modele: "NX60S-DRWC",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Batterie rechargeable Li-Ion",
          "TapControl",
          "Bouton poussoir",
          "Chargeur Premium ou standard",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "RIE 61",
        modele: "NX61-DRW",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton poussoir",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "RIE 61",
        modele: "NX61-DRW",
        checklist: [
          "Écouteurs LP, MP, HP, UP et M&RIE",
          "8 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton Multi-Fonction",
          "Bobine T",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: ficheTechnique,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "CROS Transmetteur",
        modele: "CX160S-DRWC",
        checklist: [
          "Écouteurs LP, MP & HP",
          "8 couleurs",
          "Batterie rechargeable Li-ion",
          "Bouton poussoir",
          "Chargeur Premium",
        ],
        ficheTechnique: crostransmetteur,
        imageProduct: microRIE60S,
      },
      {
        nameProduct: "BTE/PowerBTE Rechargeable",
        modele: "NX77-DWC, NX88-DWC",
        checklist: [
          "Modèles BTE et PBTE ",
          "8 couleurs",
          "Batterie rechargeable Li-ion",
          "Bouton poussoir",
          "Bobine T",
          "Option DAI pour PowerBTE",
          "Chargeur de bureau",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2,Phone Clip+, Micro Mic, Multi-Mic+ et Multi Mic",
        ],
        ficheTechnique: transCros1and2,
        imageProduct: BTEPowerBTE,
      },
      {
        nameProduct: "Transmetteur CROS BTE",
        modele: "Modèle 70",
        checklist: [
          "Transmetteur sans fil BTE qui se connecte aux BTE et intras rechargeables",
          "8 couleurs",
          "Batterie rechargeable au lithium-ion pour une durée de vie prolongée",
          "Bouton poussoir",
        ],
        ficheTechnique: transCros1and2,
        imageProduct: Transmetteur,
      },
      {
        nameProduct: "ITC Sans fil",
        modele: "ITC 312-WL",
        checklist: [
          "Options LP, MP, HP & UP",
          "4 couleurs",
          "Pile 312 Zinc-Air",
          "Bouton-poussoir programmable et contrôle du volume",
          "Multi-Mic+, TV-Streamer+, TV-Streamer 2, Remote Control, Micro Mic et Multi Mic",
        ],
        ficheTechnique: slider3pdf1,
        imageProduct: sliderimg1, // Update with actual image path
      },
      {
        nameProduct: "ITC/ITE Rechargeable",
        modele: "NXITC-DWC, NXITE-DWC",
        checklist: [
          "LP, MP, HP & UP",
          "4 couleurs",
          "Batterie rechargeable Li-Ion",
          "Chargeur intra",
          "TV-Streamer+, TV-Streamer 2, Remote Control, Remote Control 2, Phone Clip+, Micro Mic et Multi Mic",
        ],
        ficheTechnique: slider3pdf2, // You can merge ITC/ITE fiche technique if it's the same
        imageProduct: sliderimg2, // Update with actual image path
      },
      {
        nameProduct: "CIC",
        modele: "NX-CIC",
        checklist: [
          "LP, MP & HP",
          "4 couleurs",
          "Pile 10A Zinc-Air",
        ],
        ficheTechnique: slider3pdf3,
        imageProduct: sliderimg3, // Update with actual image path

      },
      {
        nameProduct: "Multi-Mic+",
        modele: "",
        checklist: [
          "Connexion via Auracast",
          "Rechargeable",
          "Double microphone pour capter la voix",
          "Connecteur FM",
          "Bobine téléphonique intégrée",
          "Connecteur mini-jack",
          "Bouton poussoir dans l'application",
        ],
        ficheTechnique: slider4pdf1, // Update with actual file path
        imageProduct: slider4img1, // Update with actual image path
      },
      {
        nameProduct: "TV-Streamer+",
        modele: "",
        checklist: [
          "Prêt pour Bluetooth Auracast",
          "Qualité sonore supérieure et consommation de batterie nettement inférieure",
          "Appairage facile aux aides auditives et à la télévision",
        ],
        ficheTechnique: slider4pdf2, // Update with actual file path
        imageProduct: slider4img2, // Update with actual image path
      },
      {
        nameProduct: "Chargeur Premium",
        modele: "",
        checklist: [
          "Dimensions: 99,4 x 35 x 67,5 mm",
          "Poids: 145 grammes",
          "Batterie rechargeable Li-Ion, 3,6V, 2600 mAh",
          "Min. 3 charges complètes de deux aides auditives, sans aides auditives: 12 mois",
          "Temps de charge de la batterie Lithium-Ion interne du chargeur: Max 3,5 heures",
        ],
        ficheTechnique: slider4pdf3, // Update with actual file path
        imageProduct: slider4img3, // Update with actual image path
      }
    ],
    img_product: moveBanner2,

  },
  {
    gamme: "accessory",
    id: 7,
    mainTitle: "Dispositifs d'aide à l'écoute",
    image: assistiveListrning,
    level: "PREMIUM",
    category: "Accessoires",
    desc: "Aider une personne malentendante ou souffrant d’un trouble de la voix, de la parole ou du langage à communiquer.",
  },
  {
    gamme: "accessory",
    id: 8,
    mainTitle: "Pile 10",
    image: pile10,
    level: "PREMIUM",
    category: "Accessoires",
    desc: "Piles auditives 10",
  },
  {
    gamme: "accessory",
    id: 9,
    mainTitle: "Piles 13",
    image: pile13,
    level: "PREMIUM",
    category: "Accessoires",
    desc: "Piles auditives",
  },
  {
    gamme: "accessory",
    id: 9,
    mainTitle: "Piles 675",
    image: pile675,
    level: "PREMIUM",
    category: "Accessoires",
    desc: "Piles auditives",
  },
  
  




];

export default hearing_aids;
