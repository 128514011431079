import React, { useState, useRef } from "react";
import guide from "../assets/guide.jpg";
import MPMstore from "../assets/about/aboutMPM.png";
import Display from "../components/Display";
import menuLink from "../menu/menuLink";
import landingVideo from "../assets/landingPage.mp4";
import logoMPM from "../assets/agreement.png";
import mic from "../assets/microphone.mp4";
import { AWARDS } from "../utils/awards";
import Slider from "react-slick";
import SubTitle from "../components/SubTitle";
import { FaAngleDoubleRight } from "react-icons/fa";
import hearing from "../assets/hearing.jpg";
// import hearingSound from "../assets/hearingSound.mp3";
import { FaPlay, FaStop } from "react-icons/fa";
import { FaMapMarker } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdMail } from "react-icons/md";
import Title from "../components/Title";
import { BLOG, PARTNERS } from "../utils/IMAGES";
import { AUDIO_LEVELS } from "../utils/audioTest";
import { saveRequestToFireStore } from "../utils/firestoreFunctions";
// import { FaWhatsapp, FaCalendar } from "react-icons/fa";
const Home = () => {
  const [playHearing, satPlayHearing] = useState("");
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const audioRef = useRef(null);

  const handlePlayNormal = () => {
    if (audioRef.current) {
      audioRef.current.play();
      satPlayHearing("normal");
    }
  };
  const handleStopNormal = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      satPlayHearing("");
    }
  };
  const handlePlayLéger = () => {
    if (audioRef.current) {
      audioRef.current.play();
      satPlayHearing("Léger");
    }
  };
  const handleStopLéger = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      satPlayHearing("");
    }
  };
  const handlePlayModéré = () => {
    if (audioRef.current) {
      audioRef.current.play();
      satPlayHearing("Modéré");
    }
  };
  const handleStopModéré = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      satPlayHearing("");
    }
  };
  const handlePlayProfond = () => {
    if (audioRef.current) {
      audioRef.current.play();
      satPlayHearing("Profond");
    }
  };
  const handleStopProfond = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      satPlayHearing("");
    }
  };

  const sendRequest = (e) => {
    e.preventDefault();
    saveRequestToFireStore(fullname, phone, email);
    setFullname(""); 
    setEmail("");
    setPhone(""); 
  };

  const aboutLink = {
    id: 1,
    title: "À propos de Maison Paramed",
    desc: "Des années d'expérience pour aider des milliers de patients à reprendre le contrôle de leur vie grâce à une technologie auditive de pointe, des solutions révolutionnaires et des soins spécialisés.",
    icon: logoMPM,
    image: [MPMstore],
    reverse: true,
    show_button: true, 
    lien: '/about'
  };
  // ------- Slider Settings ---------------
  var settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow:
      window.innerWidth < 700 ? 2 : window.innerWidth < 1000 ? 3 : 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <section className=" relative">
      <div className=" h-[300px] md:h-[400px] lg:h-[100vh] w-full relative">
        <p className=" text-[15px] sm:text-[16px] md:text-[18px] lg:leading-[50px] lg:text-[22px] xl:text-[30px] absolute pl-4 mt-12 sm:mt-20 md:mt-24 lg:mt-32 xl:mt-40 z-10 text-white">
          Présentation de Maison Paramed,
          <br />
          la plus petite aide auditive rechargeable au monde!
        </p>
        <video
          // src={vedio}
          src={landingVideo}
          autoPlay
          loop
          muted
          className=" h-full w-full object-cover"
          // controls
          // playsinline
          
          
        />
      </div>
      <div className="px-4 pt-10 sm:px-8 md:px-16 lg:px-24 xl:px-36">
        <Display item={aboutLink} />
        <div className=" mt-6">
          {menuLink.map((item, index) => (
            <Display color="#1d84bd17" key={index} item={item} />
          ))}
        </div>
      </div>
      <div className=" relative w-full bg-amber-300 my-6 text-center">
        <img
          src={guide}
          className=" object-cover z-0 absolute w-full h-full"
          alt=""
        />
        <h1 className=" relative py-8 flex items-center m-auto flex-col md:flex-wrap text-[20px] font-semibold text-white gap-2 w-fit">
          Découvrez votre guide étape par étape de votre voyage avec nous{" "}
          <span>
            <FaAngleDoubleRight className=" hover:text-custom-primary duration-300 transition-all cursor-pointer text-[30px]" />
          </span>
        </h1>
      </div>
      <div>
        <SubTitle
          maintitle="Le premier et unique au monde"
          subTitle={["M&RIE", "& Receiver-In-Ear design", "a Microphone"]}
        />

        <a href="/">
          {" "}
          <div className="flex items-start px-4 sm:px-8 flex-col lg:flex-row md:px-16 lg:px-24 xl:px-36">
            {" "}
            <div className=" flex-col flex gap-4">
              <div>
                <h1 className=" text-[15px] lg:text-[20px] mb-1 text-custom-primary font-normal md:text-[19px]">
                  Découvrez M&RIE
                </h1>
                <p className=" text-[12px] md:text-[14px] lg:text-[16px] text-gray-600 leading-[25px]">
                  Le microphone et le récepteur dans votre oreille utilisent la
                  forme unique de votre oreille pour capter le son de la manière
                  dont la nature l'a prévu pour obtenir la qualité sonore la
                  plus naturelle.
                </p>
              </div>
              <div>
                <h1 className=" text-[15px] lg:text-[20px] mb-1 text-custom-primary font-normal md:ext-[19px]">
                  Clarté de la parole dans le bruit du vent
                </h1>
                <p className=" text-[12px] md:text-[14px] lg:text-[16px] text-gray-600 leading-[25px]">
                  Regardez la vidéo et découvrez comment ReSound ONE™ permet une
                  meilleure compréhension de la parole dans une situation
                  venteuse.
                </p>
              </div>
              <div>
                <h1 className=" text-[15px] lg:text-[20px] mb-1 text-custom-primary font-normal md:ext-[19px]">
                  Promenade venteuse avec et sans M&RIE
                </h1>
                <p className=" text-[12px] md:text-[14px] lg:text-[16px] text-gray-600 leading-[25px]">
                  Le microphone dans votre oreille vous permet de profiter des
                  sons naturels de votre environnement sans écraser le bruit du
                  vent. Écoutez la différence.
                </p>
              </div>
              <div>
                <h1 className=" text-[15px] text-custom-primary font-normal md:ext-[19px]">
                  Dans un restaurant
                </h1>
                <p className=" text-[12px] md:text-[14px] lg:text-[16px] text-gray-600 leading-[25px]">
                  Échantillonnez la différence sonore lorsque vous utilisez
                  ReSound ONE avec M&RIE dans un restaurant animé.
                </p>
              </div>
            </div>
            <div>
              <video
                src={mic}
                autoPlay
                loop
                muted
                className=" h-full w-full object-cover"
              />
            </div>
          </div>
        </a>
      </div>

      {/* --------------------- Hearing Test  */}
      <div className="px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36 mt-4  ">
        <div className=" relative w-full ">
          <div className=" absolute z-10 w-full h-full bg-gradient-to-r from-custom-primary to-transparent "></div>
          <img
            src={hearing}
            className=" object-cover absolute w-full h-full z-0"
            alt={hearing.name}
          />

          <div className=" z-20 relative text-white w-[100%] md:w-[80%] lg:w-[50%] pl-4 py-6 flex flex-col gap-5">
            <h1 className=" font-medium text-[16px] md:text-[18px] lg:text-[24px] xl:text-[30px]">
              Découvrez ce que c'est que de vivre avec une perte auditive
            </h1>
            <div className=" flex gap-2 items-center  border-[2px] border-white w-fit p-3 lg:p-6">
              <div className=" flex flex-col gap-3 items-center">
                <span>Normal</span>
                {playHearing === "normal" ? (
                  <FaStop
                    className=" cursor-pointer"
                    onClick={handleStopNormal}
                  />
                ) : (
                  <FaPlay
                    className=" cursor-pointer "
                    onClick={handlePlayNormal}
                  />
                )}
                <audio ref={audioRef} src={AUDIO_LEVELS.normalAudio} />
              </div>
              <div className=" flex flex-col gap-3 items-center">
                <span>Léger</span>
                {playHearing === "Léger" ? (
                  <FaStop
                    className=" cursor-pointer"
                    onClick={handleStopLéger}
                  />
                ) : (
                  <FaPlay
                    className=" cursor-pointer "
                    onClick={handlePlayLéger}
                  />
                )}
                <audio ref={audioRef} src={AUDIO_LEVELS.mildAudio} />
              </div>
              <div className=" flex flex-col gap-3 items-center">
                <span>Modéré</span>
                {playHearing === "Modéré" ? (
                  <FaStop
                    className=" cursor-pointer"
                    onClick={handleStopModéré}
                  />
                ) : (
                  <FaPlay
                    className=" cursor-pointer "
                    onClick={handlePlayModéré}
                  />
                )}
                <audio ref={audioRef} src={AUDIO_LEVELS.moderateAudio} />
              </div>
              <div className=" flex flex-col gap-3 items-center">
                <span>Profond</span>
                {playHearing === "Profond" ? (
                  <FaStop
                    className=" cursor-pointer"
                    onClick={handleStopProfond}
                  />
                ) : (
                  <FaPlay
                    className=" cursor-pointer "
                    onClick={handlePlayProfond}
                  />
                )}
                <audio ref={audioRef} src={AUDIO_LEVELS.profoundAudio} />
              </div>
            </div>
            <p>
              Basculez entre les différents fichiers audio pour entendre la
              différence entre l’audition normale et les différents degrés de
              perte auditive !
            </p>
            {/* <h3>
              Cliquez ci-dessous pour effectuer notre test de perte auditive en
              ligne gratuit.
            </h3>
            <a
              className=" capitalize py-2 px-3 bg-white rounded-sm text-custom-primary text-[13px] md:text-[14px] lg:text-[17px] font-normal w-fit"
              href="/"
            >
              faire le test
            </a> */}
          </div>
        </div>
      </div>
      {/* --------------------- SECTION ADDRESS and CTA */}
      <section className="px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36 pt-9 bg-slate-300/20 placeholder-gray-300">
        <SubTitle
          maintitle="Vous offrir un plus"
          subTitle={["Technologie avancée", "Solution sur mesure"]}
        />
        <div className=" flex flex-col lg:flex-row gap-6 justify-between">
          <div className=" flex flex-col gap-5">
            <a
              href="/"
              className=" flex gap-2 items-center text-[14px] text-gray-500"
            >
              <FaMapMarker className=" text-[20px] text-custom-primary " />
              K.O.M, Lot. Al Wafaa, N° 15, Hay Al Farah, Sect. 4, Salé 11004
            </a>
            <a
              href="/"
              className=" flex gap-2 items-center text-[14px] text-gray-500"
            >
              <IoCall className=" text-[20px] text-custom-primary " /> 05 37 83
              12 03
            </a>
            <a
              href="/"
              className=" flex gap-2 items-center text-[14px] text-gray-500"
            >
              <MdMail className=" text-[20px] text-custom-primary " />{" "}
              maisonparamed@gmail.com
            </a>
          </div>
          {/* <div className=" flex flex-col gap-5">
            <a
              href="/"
              className=" flex gap-2 items-center text-[14px] text-gray-500"
            >
              <FaMapMarker className=" text-[20px] text-custom-primary " /> 108
              Khalifa Bin Zayed The First St. Al Khaldiya
            </a>
            <a
              href="/"
              className=" flex gap-2 items-center text-[14px] text-gray-500"
            >
              <IoCall className=" text-[20px] text-custom-primary " /> +971 4
              239 0572
            </a>
            <a
              href="/"
              className=" flex gap-2 items-center text-[14px] text-gray-500"
            >
              <MdMail className=" text-[20px] text-custom-primary " />{" "}
              maisonparamed@gmail.com
            </a>
          </div> */}
          <form
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            action=""
            className=" py-8 rounded-t-[40px] px-5 text-start flex flex-col gap-6 pb-20"
          >
            <h3 className=" text-gray-500 font-medium text-[18px]">
              Demander un rappel
            </h3>
            <div className=" flex flex-col gap-6">
              <input
                type="text"
                className=" py-3 px-3 border outline-none font-extralight"
                placeholder="nom et prénom*"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
              <input
                type="text"
                className=" py-3 px-3 border outline-none font-extralight"
                placeholder="phone*: 06xxxxxxxx "
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                type="text"
                className=" py-3 px-3 border outline-none font-extralight"
                placeholder="Email*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className=" py-2 px-6  bg-custom-primary hover:bg-custom-primary/60 duration-200 transition-all  text-white rounded-md"
              onClick={sendRequest}
            >
              envoyer une demande{" "}
            </button>
          </form>
        </div>
      </section>
      <div className="px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36">
        <Title title="Nos dernières actualités" />
        <div className=" gap-3 md:gap-4 lg:gap-8  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <a href="/comprende-la-perte-auditive" className=" group">
            <img className="" src={BLOG.Understanding} alt="" />
            <p className=" group-hover:text-custom-secondary font-medium text-[14px] md:text-[15px] p-3 lg:text-[17px] duration-200 transition-all text-custom-primary">
              Comprendre la perte auditive soudaine : ce que vous devez savoir
            </p>
            <span className=" text-gray-500 text-[14px]">Août 15, 2024</span>
          </a>
          <a href="/protection-auditive-sur-mesure" className=" group">
            <img src={BLOG.protection} alt="" />
            <p className=" group-hover:text-custom-secondary font-medium text-[14px] md:text-[15px] p-3 lg:text-[17px] duration-200 transition-all text-custom-primary">
              Protection auditive sur mesure
            </p>
            <span className=" text-gray-500 text-[14px]">Août 15, 2024</span>
          </a>
          <a
            href="/pourquoi-vous-pourriez-avoir-besoin-multi-mic"
            className=" group"
          >
            <img src={BLOG.Mic} alt="" />
            <p className=" group-hover:text-custom-secondary font-medium text-[14px] md:text-[15px] p-3 lg:text-[17px] duration-200 transition-all text-custom-primary">
              Vous portez des appareils auditifs ? Voici pourquoi vous pourriez
              avoir besoin d’un Multi Mic
            </p>
            <span className=" text-gray-500 text-[14px]">Août 15, 2024</span>
          </a>
        </div>
        {/* <a
          href="/#"
          className=" flex font-medium text-white items-center justify-center bg-custom-primary w-full py-3 my-8 hover:bg-custom-primary/80  duration-300 transition-all  text-[16px] md:text-[20px] lg:[26px]"
        >
          Voir plus d'articles
        </a> */}
        <div>
          <Title title="Partenaires d'assurance" />
          <div className=" flex justify-evenly flex-col md:flex-row items-center mt-10 mb-20">
            {PARTNERS.map((parnter, index) => (
              <img
                className=" h-[130px]"
                key={index}
                src={parnter.image}
                alt={parnter.name}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="py-12 bg-gray-200 px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36">
        <div className="flex justify-center items-center w-full">
          <p className="font-normal text-[15px] sm:text-[16px] md:text-[18px] xl:text-[30px] text-custom-primary pb-14">
            Prix Technologiques ReSound
          </p>
        </div>
        <div className="pb-6 w-23/24 sm:w-full ">
          <Slider {...settings}>
            {AWARDS.map((e, i) => (
              <div key={i}>
                <img src={e} alt={`award-${i}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Home;
