import React from "react";
import TitleWithImage from "../../components/TitleWithImage";
import image from "../../assets/guide.jpg";
import { BLOG } from "../../utils/IMAGES";
function BlogHearingLoss({ date_published }) {
  // What is sudden hearing loss?
  const what_is_sudden_loss =
    "La surdité neurosensorielle soudaine (SSNHL) est une perte auditive d'apparition rapide qui peut se produire dans une ou deux oreilles. Elle se produit généralement en quelques heures ou quelques jours et nécessite une attention immédiate.";
  //   Symptoms of sudden hearing loss
  const symptoms = [
    {
      name: "Acouphènes",
      description:
        "Les personnes concernées peuvent ressentir des bourdonnements, des sifflements ou des grondements dans l'oreille touchée.",
    },
    {
      name: "Apparition rapide",
      description:
        "La perte auditive soudaine, également connue sous le nom de perte auditive neurosensorielle soudaine (SSHL), se développe généralement rapidement sur une période de quelques heures à 3 jours.",
    },
    {
      name: "Difficulté à comprendre la parole",
      description:
        "Difficulté à comprendre la parole des autres, en particulier dans les environnements bruyants.",
    },
    {
      name: "Perte auditive unilatérale",
      description:
        "Elle affecte souvent une seule oreille et peut s'accompagner d'un bourdonnement ou d'une sonnerie forte.",
    },
  ];
  const cause_desc =
    "Le syndrome respiratoire aigu sévère (SSNHL) diffère des autres types de perte auditive sur plusieurs points importants. Outre son apparition rapide, il est souvent idiopathique, ce qui signifie que sa cause est généralement inconnue. Cependant, les causes prédites les plus courantes sont les suivantes:";
  // The cause of sudden hearing loss
  const causes = [
    {
      name: "Infections bactériennes et virales",
      description:
        "Des maladies telles que la labyrinthite et les oreillons peuvent entraîner une perte soudaine de l'audition.",
    },
    {
      name: "Maladies vasculaires",
      description:
        "Des maladies telles que l'hypertension et l'athérosclérose peuvent affecter le flux sanguin vers l'oreille interne, entraînant une perte soudaine de l'audition.",
    },
    {
      name: "Médicaments ototoxiques",
      description:
        "Certains médicaments, notamment les médicaments de chimiothérapie et les fortes doses d'aspirine, peuvent avoir pour effet secondaire une perte soudaine de l'audition.",
    },
    {
      name: "Troubles de l'oreille interne",
      description: "Affections telles que la maladie de Ménière",
    },
    {
      name: "Traumatisme physique",
      description:
        "Les traumatismes crâniens dus à des accidents ou à des sports de contact peuvent entraîner une perte soudaine de l'audition.",
    },
  ];

  //   Treating sudden hearing loss

  const treatment = [
    {
      name: "Obtenir des soins médicaux immédiats",
      description:
        "Contactez un professionnel de la santé dès que possible pour un examen approfondi et demandez un traitement médical auprès d'un spécialiste ORL, tout en effectuant des tests de diagnostic auprès d'un audiologiste. Si le traitement médical n'est pas suffisant, l'utilisation d'un appareil auditif peut être une option appropriée.",
    },
    {
      name: "Le soutien psychologique",
      description:
        "Le soutien psychologique et les conseils sont essentiels pour faire face à l'impact émotionnel d'une perte auditive soudaine.",
    },
  ];

  //   Recovery and Rehabilitation
  const recovery = [
    {
      name: "Délais de guérison",
      description:
        "La période de récupération varie d'une personne à l'autre, et certaines personnes peuvent connaître une récupération spontanée au cours des deux premières semaines.",
    },
  ];

  const recovery_desc =
    "La fenêtre de restauration de l'audition se referme deux à quatre semaines après l'apparition de la perte auditive. Après cette courte période, sans traitement, la perte auditive deviendra probablement permanente et irréversible.";
  // Prevention of Sudden Hearing Loss
  const prevention = [
    {
      name: "Mesures de protection",
      description:
        "L'utilisation de protections auditives dans des environnements bruyants et lors d'activités telles que les concerts et les sports de tir peut prévenir les dommages au système auditif.",
    },
    {
      name: "Mode de vie sain",
      description:
        "La prise en charge de problèmes de santé sous-jacents tels que l'hypertension et le diabète peut réduire le risque de perte auditive soudaine d'origine vasculaire.",
    },
  ];
  return (
    <section className=" ">
      {/* ---------- BLOG IMAGE  */}
      <TitleWithImage
        image={image}
        title="Comprendre la perte auditive soudaine : ce que vous devez savoir"
      />
      {/* ---------- BLOG   */}
      <div className="flex px-8 py-10 justify-center flex-col max-w-3xl m-auto">
        <img className="" src={BLOG.Understanding} alt="" />
        {/* ---------- Qu'est-ce qu'une perte auditive soudaine   */}
        <div className="py-8">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Qu'est-ce qu'une perte auditive soudaine ?
          </p>
          <p className="text-sm text-gray-600 leading-loose">
            {what_is_sudden_loss}
          </p>
        </div>

        {/* ---------- Symptômes de la perte auditive soudaine  */}
        <div className="py-8">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Symptômes de la perte auditive soudaine
          </p>
          {symptoms.map((e, index) => (
            <div className="py-4">
              <p className="font-medium text-lg text-custom-secondary">
                {e.name}
              </p>
              <p className="text-sm text-gray-600 leading-loose">
                {e.description}
              </p>
            </div>
          ))}
        </div>

        {/* ---------- La cause de la perte auditive soudaine   */}

        <div className="py-8">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            La cause de la perte auditive soudaine
          </p>
          <p className="text-sm text-gray-600 leading-loose">{cause_desc}</p>
          {causes.map((e, index) => (
            <div className="py-4">
              <p className="font-medium text-lg text-custom-secondary">
                {e.name}
              </p>
              <p className="text-sm text-gray-600 leading-loose">
                {e.description}
              </p>
            </div>
          ))}
        </div>
        {/* ---------- Récupération et réadaptation   */}

        <div className="py-8">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Récupération et réadaptation
          </p>
          <p className="text-sm text-gray-600 leading-loose">{recovery_desc}</p>
          {recovery.map((e, index) => (
            <div className="py-4">
              <p className="font-medium text-lg text-custom-secondary">
                {e.name}
              </p>
              <p className="text-sm text-gray-600 leading-loose">
                {e.description}
              </p>
            </div>
          ))}
        </div>
        {/* ---------- Traitement de la perte auditive soudaine */}
        <div className="py-8">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Traitement de la perte auditive soudaine
          </p>
          {treatment.map((e, index) => (
            <div className="py-4">
              <p className="font-medium text-lg text-custom-secondary">
                {e.name}
              </p>
              <p className="text-sm text-gray-600 leading-loose">
                {e.description}
              </p>
            </div>
          ))}
        </div>
        {/* ---------- Prévention de la perte auditive soudaine */}
        <div className="py-8">
          <p className="text-xl font-semibold text-custom-primary pb-8">
            Prévention de la perte auditive soudaine
          </p>
          <p className="text-sm text-gray-600 leading-loose">{cause_desc}</p>
          {prevention.map((e, index) => (
            <div className="py-4">
              <p className="font-medium text-lg text-custom-secondary">
                {e.name}
              </p>
              <p className="text-sm text-gray-600 leading-loose">
                {e.description}
              </p>
            </div>
          ))}
        </div>

        <div className="border-t border-b border-gray-400 border-solid">
          <p className="text-sm text-gray-600 leading-loose">15 août 2024</p>
        </div>
      </div>
    </section>
  );
}

export default BlogHearingLoss;
