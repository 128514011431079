import React from 'react'
import {
    samsungPhones,
    googlePhones,
    onePlusPhones,
    otherPhones,
  } from "../utils/data";
function AndroidDevices() {
  return (
<div class="flex flex-col  gap-4 bg-white px-4 min-h-[72px] py-2 ">
      <div class="flex flex-col justify-center">
        <p class="text-custom-secondary text-base font-medium leading-normal line-clamp-1">
          Google Pixel
        </p>
        <ul className="list-disc list-inside bg-white shadow-md rounded-lg p-4">
          {googlePhones.map((phone, index) => (
            <li
              key={index}
              className="text-xs py-2 px-4 border-b border-gray-200 text-gray-700 hover:bg-gray-100 transition duration-150 ease-in-out"
            >
              {phone}
            </li>
          ))}
        </ul>
      </div>
      <div class="">
        <p class="text-custom-secondary text-base font-medium leading-normal line-clamp-1">
          Samsung
        </p>
        <ul className="list-disc list-inside bg-white shadow-md rounded-lg p-4">
          {samsungPhones.map((phone, index) => (
            <li
              key={index}
              className=" text-xs py-2 px-4 border-b border-gray-200 text-gray-700 hover:bg-gray-100 transition duration-150 ease-in-out"
            >
              {phone}
            </li>
          ))}
        </ul>
      </div>

      <div class="">
        <p class="text-custom-secondary text-base font-medium leading-normal line-clamp-1">
          OnePlus
        </p>
        <ul className="list-disc list-inside bg-white shadow-md rounded-lg p-4">
          {onePlusPhones.map((phone, index) => (
            <li
              key={index}
              className=" text-xs py-2 px-4 border-b border-gray-200 text-gray-700 hover:bg-gray-100 transition duration-150 ease-in-out"
            >
              {phone}
            </li>
          ))}
        </ul>
      </div>
      <div class="">
        <p class="text-custom-secondary text-base font-medium leading-normal line-clamp-1">
          Others
        </p>
        <ul className="list-disc list-inside bg-white shadow-md rounded-lg p-4">
          {otherPhones.map((phone, index) => (
            <li
              key={index}
              className=" text-xs py-2 px-4 border-b border-gray-200 text-gray-700 hover:bg-gray-100 transition duration-150 ease-in-out"
            >
              {phone}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AndroidDevices