import { FaWhatsapp, FaCalendar } from "react-icons/fa6";
// import for services 
import Pediatric from "../assets/services/Pediatric Rehabilitation.jpg";
import Therapy from "../assets/services/Therapy.jpg";
import printing3d from "../assets/services/printing3d.jpg";
import Measurements from "../assets/services/Real-Ear-Measurements.jpg";
import telesante from "../assets/services/TeleSante.webp";
import Audiological from "../assets/services/Audiological.jpg";

// Images

import img1 from '../assets/services/img1evaluation.jpeg'; 
import img2 from '../assets/services/img2.jpeg'; 
import img3 from '../assets/services/img3.jpeg'; 
import img5 from '../assets/services/img5.jpeg'; 

const BLOG = {
  Mic: require("../assets/blog/Mic.jpg"),
  Understanding: require("../assets/blog/Understanding Sudden Hearing Loss.jpg"),
  protection: require("../assets/blog/hearing protection.jpg"),
};
// const TEAMS = {
//   GeneralManager: require('../assets/teams/GeneralManager.webp'),
//   Audiologist: require('../assets/teams/khadija.jpg'),
//   Asmae : require('../assets/teams/asmae.jpg'),
//   Omar : require('../assets/teams/omar.jpg'),
//   Aya : require('../assets/teams/aya.jpg'),
//   Ahmed : require('../assets/teams/ahmed.jpg')
// }
const PARTNERS = [
  // { image: require("../assets/Partners/P1.png") },
  // { image: require("../assets/Partners/P2.png") },
  // { image: require("../assets/Partners/P3.png") },
  { image: require("../assets/Partners/cnops.png") },
  { image: require("../assets/Partners/far.png") },
];
const ICONS = [
  { name: <FaWhatsapp />, text: "Discutons  " },
  { name: <FaCalendar />, text: "Réservez maintenant  " },
  // { name: <BiSolidPhoneCall /> , text: "Appelez maintenant"},
];
const SERVICES = [
  {
    id:1,
    name:"Evaluation",
    image:img1,
    lien:"/services"
  },
  {
    id:2,
    name:"Réadaptation",
    image:img2,
    lien:"/services"
  },
  {
    id:3,
    name:"Thérapie",
    image:img3,
    lien:"/services"
  },
  {
    id:5,
    name:"Mesures",
    image:Measurements,
    lien:"/services"
  },
  {
    id:6,
    name:"TeleSante",
    image:img5,
    lien:"/services"
  }
]

export { BLOG, PARTNERS, ICONS , SERVICES};
