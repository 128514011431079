import React from 'react'
import swim from "../../assets/hearing Protection/swim.jpg"
const Swim = () => {
  return (
    <div className=" flex flex-col gap-5 lg:gap-6 lg:flex-row" data-aos="fade-zoom-in">
    <div className="  flex items-center lg:w-[50%]  gap-2">
     <div className=" relative h-[250px] md:h-[300px] lg:h-[530px] w-full">
          <img className=" absolute w-full h-full  object-cover" src={swim} alt="" />
     </div>   
    </div>
    <div className="lg:w-[50%] flex flex-col gap-4">
      <h2 className=" m-auto lg:m-0 capitalize lg:text-[25px] text-custom-primary">
      Bouchons de natation/sommeil
      </h2>
      <p  className=" text-[15px] text-[#5b636c] leading-8" >
      Les bouchons de natation sont des dispositifs de protection personnalisés conçus pour protéger les oreilles de l’eau pendant la natation ou la pratique d’activités aquatiques. Ils sont généralement fabriqués à partir de matériaux imperméables tels que le silicone ou le caoutchouc et sont ajustés sur mesure pour assurer un ajustement sûr et confortable. Les bouchons de natation créent un joint étanche dans le conduit auditif, empêchant l’eau de pénétrer et réduisant le risque de maladies telles que l’oreille du nageur ou les infections de l’oreille. Ils sont particulièrement bénéfiques pour les personnes qui ont des antécédents d’infections de l’oreille, de perforation des tympans ou qui ont subi une intervention chirurgicale de l’oreille.
      En utilisant des bouchons de natation, les nageurs peuvent profiter de leur temps dans l’eau sans compromettre la santé de leurs oreilles, ce qui leur permet de se concentrer sur leurs performances et leur plaisir tout en minimisant les risques potentiels associés à l’exposition à l’eau.
      </p>
     
    </div>
  </div>
  )
}

export default Swim