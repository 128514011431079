const googlePhones = [
    "Google Pixel 7 Pro",
    "Google Pixel 7",
    "Google Pixel 6 Pro",
    "Google Pixel 6a",
    "Google Pixel 6",
    "Google Pixel 5a 5G",
    "Google Pixel 5",
    "Google Pixel 4 XL",
    "Google Pixel 4a 5G",
    "Google Pixel 4a",
    "Google Pixel 4",
    "Google Pixel 3a",
    "Google Pixel 3a XL",
    "Google Pixel 3",
    "Google Pixel 3XL"
];

const samsungPhones = [
    "Samsung Galaxy S23*",
    "Samsung Galaxy S23 Plus*",
    "Samsung Galaxy S23 Ultra*",
    "Samsung Galaxy S22 Ultra 5G",
    "Samsung Galaxy S22+ 5G",
    "Samsung Galaxy S22 5G",
    "Samsung Galaxy S21",
    "Samsung Galaxy S21+",
    "Samsung Galaxy S21 Ultra",
    "Samsung Galaxy S20",
    "Samsung Galaxy S20 +",
    "Samsung Galaxy S20 Ultra 5G",
    "Samsung Galaxy S10",
    "Samsung Galaxy S10+",
    "Samsung Galaxy S10e",
    "Samsung Galaxy S9+",
    "Samsung Galaxy S9",
    "Samsung Galaxy S8+",
    "Samsung Galaxy S8",
    "Samsung Galaxy S7 edge",
    "Samsung Galaxy S7",
    "Samsung Galaxy Note 10+",
    "Samsung Galaxy Note 10",
    "Samsung Galaxy Note 9",
    "Samsung Galaxy Note 8",
    "Samsung Galaxy J7 Prime",
    "Samsung Galaxy J5",
    "Samsung Galaxy A51",
    "Samsung Galaxy S20 FE"
];

const otherPhones = [
    "Oppo Reno 10x Zoom",
    "Asus Zenfone 6",
    "Moto G7",
    "Xiaomi Mi 9"
];
const onePlusPhones = [
    "OnePlus 9 Pro",
    "OnePlus 9",
    "OnePlus 9 R",
    "OnePlus 7 Pro",
    "OnePlus 7 T"
];

const iPhoneModels = [
    "iPhone 15 Pro Max",
    "iPhone 15 Pro",
    "iPhone 15 Plus",
    "iPhone 15",
    "iPhone 14 Pro Max",
    "iPhone 14 Pro",
    "iPhone 14 Plus",
    "iPhone 14",
    "iPhone 13 Pro Max",
    "iPhone 13 Pro",
    "iPhone 13",
    "iPhone 13 mini",
    "iPhone 12 Pro Max",
    "iPhone 12 Pro",
    "iPhone 12",
    "iPhone 12 mini",
    "iPhone 11 Pro Max",
    "iPhone 11 Pro",
    "iPhone 11",
    "iPhone SE (2ème génération)",
    "iPhone XS",
    "iPhone XS Max",
    "iPhone XR",
    "iPhone X",
    "iPhone 8 Plus",
    "iPhone 8",
    "iPhone 7 Plus",
    "iPhone 7",
    "iPhone 6s Plus",
    "iPhone 6s",
    "iPhone 6 Plus",
    "iPhone 6",
    "iPhone SE",
    "iPhone 5s"
];

const iPadModels = [
    "Pad Pro (12,9 pouces)",
    "iPad Pro (10,5 pouces)",
    "iPad Pro (9,7 pouces)",
    "iPad Pro 12,9 pouces (5ème génération)",
    "iPad Pro 12,9 pouces (4ème génération)",
    "iPad Pro 12,9 pouces (3ème génération)",
    "iPad Pro 12,9 pouces (2ème génération)",
    "iPad Pro 12,9 pouces (1ère génération)",
    "iPad Pro 11 pouces (3ème génération)",
    "iPad Pro 11 pouces (2ème génération)",
    "iPad Pro 11 pouces (1ère génération)",
    "iPad Pro 10,5 pouces",
    "iPad Pro 9,7 pouces",
    "iPad Air (4ème génération)",
    "iPad Air (3ème génération)",
    "iPad Air 2",
    "iPad Air",
    "iPad mini (6ème génération)",
    "iPad mini (5ème génération)",
    "iPad mini 4",
    "iPad mini 3",
    "iPad mini 2",
    "iPad mini 1",
    "iPad (9ème génération)",
    "iPad (8ème génération)",
    "iPad (7ème génération)",
    "iPad (6ème génération)",
    "iPad (5ème génération)"
];

export { googlePhones, samsungPhones, onePlusPhones, otherPhones, iPhoneModels, iPadModels }; 