import React from "react";
// import Pediatric from "../../assets/services/Pediatric Rehabilitation.jpg";
import Pediatric from "../../assets/services/img6.jpeg";
// import Adult from "../../assets/services/Rehabilitation Adult.jpg";
import Adult from "../../assets/services/img2.jpeg";
const Readaptation = () => {
  return (
    <div>
      <div className=" flex flex-col gap-3 lg:gap-6">
        <div className=" flex items-center gap-3 md:gap-[20px] lg:gap-[50px] flex-col md:flex-row mb-10">
          <div className=" w-[100%] h-[250px] md:h-[300px] lg:h-[400px] relative md:w-[50%]">
            <img src={Adult} alt="" className=" absolute w-full h-full" />
          </div>
          <div className=" w-[100%] md:w-[50%] pt-10">
            <div className=" flex flex-col gap-3">
              <h1 className="lg:text-[28px] text-custom-primary font-medium capitalize">
                Réadaptation pour adultes
              </h1>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                La perte auditive chez l’adulte, qu’elle soit légère ou sévère,
                peut avoir des conséquences néfastes sur notre vie
                professionnelle et sociale. Nous pensons que chaque patient a
                des besoins auditifs très spécifiques, c’est pourquoi nous
                privilégions l’évaluation et la discussion avant toute étape.
                L’audition de chaque patient sera testée par un expert et ses
                besoins en matière de communication seront discutés en détail.
                Un plan de traitement sera créé sur la base de nos discussions
                bilatérales et adapté de manière à offrir la meilleure solution
                possible à chaque patient.
              </p>
            </div>
          </div>
        </div>
        <div className=" flex items-center gap-3 md:gap-[20px] lg:gap-[50px] flex-col md:flex-row mb-10">
          <div className=" w-[100%] h-[250px] md:h-[300px] lg:h-[400px] relative md:w-[50%]">
            <img src={Pediatric} alt="" className=" absolute w-full h-full" />
          </div>
          <div className=" w-[100%] md:w-[50%]">
            <div className=" flex flex-col gap-3 ">
              <h1 className="lg:text-[28px] text-custom-primary font-medium capitalize">
                Réadaptation pédiatrique
              </h1>
              <p className="text-[#585858] text-[13px] lg:text-[15px]">
                Nos enfants sont comme des flocons de neige. Chacun est unique
                et magique à sa manière. C'est pourquoi nous nous efforçons
                d'aider les enfants malentendants en leur proposant des
                solutions adéquates pour répondre à leurs besoins spécifiques de
                manière efficace et rapide. Les petits enfants ne doivent pas
                être traités comme des adultes. La plupart du temps, ils ne
                peuvent pas exprimer ou indiquer ce qu'ils entendent et comment
                ils l'entendent. Il est de notre responsabilité de nous assurer
                que leurs besoins auditifs sont satisfaits et qu'ils font
                l'objet d'un suivi attentif. En utilisant des méthodes et des
                technologies de pointe, nous nous assurons que chaque enfant est
                capable d'entendre afin qu'il puisse exceller socialement et
                scolairement. Nos services comprennent des tests pour les
                enfants de la naissance à l'adolescence. Ces évaluations
                comprennent l'audiométrie comportementale, l'audiométrie par
                renforcement visuel, l'audiométrie ludique, la tympanométrie,
                les émissions otoacoustiques, les mesures de l'oreille réelle,
                les plans de traitement et les questionnaires.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Readaptation;
