import React from "react";
import telesante from "../../assets/services/img5.jpeg";
const TeleSante = () => {
  return (
    <div className=" flex gap-3 md:gap-[20px] lg:gap-[50px] flex-col md:flex-row">
      <div className=" w-[100%] h-[250px] md:h-[300px] lg:h-[400px] relative md:w-[50%]">
        <img src={telesante} alt="" className=" absolute w-full h-full object-cover" />
      </div>
      <div className=" w-[100%] md:w-[50%] pt-10">
        <div className=" flex flex-col gap-3">
          <h1 className="lg:text-[28px] text-custom-primary font-medium capitalize">
            Rendez-vous de télésanté
          </h1>
          <ul className=" flex flex-col gap-4 pl-6 list-[circle] text-[#585858] text-[13px] lg:text-[15px]">
            <li>
              Nos patients ont la possibilité de choisir un rendez-vous de
              télésanté qui implique la planification en ligne des rendez-vous
              via notre application mobile
            </li>
            <li>
              Nos audiologistes peuvent se connecter aux aides auditives d’un
              patient via nos applications en ligne et partager un écran vidéo
              où le patient et le clinicien peuvent communiquer efficacement
            </li>
            <li>
              Ces services sont fournis à la demande du patient et conseillés si
              les patients présentent un risque élevé d'infections au covid19.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeleSante;
