import img1_tvstream from '../assets/accessories/1-tvstream.jpg';
import img2 from '../assets/accessories/2-img.jpg';
import img3 from '../assets/accessories/3-img.jpg';
import img4 from '../assets/accessories/4-img.jpg';
import img5 from '../assets/accessories/5-img.jpg';
import img6 from '../assets/accessories/6-img.jpg';
import pdf1_tvstream from '../assets/accessories/1-tvstream.pdf';
import pdf12_tvstream from '../assets/accessories/1-tvstream12.pdf';
import pdf2 from '../assets/accessories/20.pdf';
import pdf3 from '../assets/accessories/30.pdf';
import pdf4 from '../assets/accessories/40.pdf';
import pdf5 from '../assets/accessories/50.pdf';
import pdf6 from '../assets/accessories/60.pdf';



const accessories = [
    {
        id: 1,
        name: "ReSound TV-Streamer+",
        image: img1_tvstream,
        checklist: [
            "Diffuse un son de haute qualité avec une clarté vocale directement vers les aides auditives ReSound Nexia au volume préféré",
            "Le nouveau OneTouch Pairing permet aux aides auditives de se coupler facilement au TV-Streamer+ sans avoir à redémarrer",
            "Plusieurs utilisateurs équipés d'aides auditives et de smartphones compatibles peuvent profiter du son ensemble",
            "Design épuré, léger et élégant, pouvant être installé verticalement à l'arrière du téléviseur",
            "Prêt pour Bluetooth® Auracast™, permettant à un nombre illimité d'appareils compatibles Auracast d'écouter un flux audio diffusé",
        ],
        file: [pdf1_tvstream, pdf12_tvstream]
    },
    {
        id: 2,
        name: "Le Micro Mic ReSound",
        image: img2,
        checklist: [
            "Microphone cravate discret qui diffuse la voix de l'utilisateur directement vers les aides auditives de votre patient",
            "Améliore considérablement le rapport signal/bruit dans les situations d'écoute difficiles pour une compréhension exceptionnelle de la parole",
            "Étend la portée de l'audition jusqu'à 15 mètres sans obstacle",
            "Possibilité de régler le volume et d'activer le mode silencieux du son diffusé",
            "Le détecteur de chute arrête automatiquement le signal sonore momentanément en cas de chute de plus de 90 cm"
        ]
        ,
        file: [pdf2]
    },
    {
        id: 3,
        name: "Le Multi Mic ReSound",
        image: img3,
        checklist: [
            "Microphone cravate qui diffuse la voix de l'utilisateur directement vers les aides auditives de votre patient",
            "Passe automatiquement en microphone de table lorsqu'il est placé horizontalement pour permettre aux patients d'entendre les voix de plusieurs locuteurs",
            "Améliore considérablement le rapport signal/bruit dans les situations d'écoute difficiles pour une compréhension exceptionnelle de la parole",
            "Possibilité de connexion avec des systèmes de boucle et FM et dispose d'une entrée mini-jack pour diffuser de l'audio à partir de pratiquement n'importe quelle source",
            "Étend la portée de l'audition jusqu'à 15 mètres sans obstacle",
            "Le détecteur de chute arrête automatiquement le signal sonore momentanément en cas de chute de plus de 90 cm"
        ],
        file: [pdf3]
    },
    {
        id: 4,
        name: "ReSound TV Streamer 2",
        image: img4,
        checklist: [
            "Diffuse un signal audio stéréo depuis un téléviseur, ordinateur ou lecteur de musique directement vers les aides auditives sans fil ReSound",
            "Se reconnecte automatiquement si l'utilisateur devient hors de portée et revient dans les 5 minutes",
            "Possibilité de régler l'équilibre entre le son diffusé et le volume des aides auditives",
            "Présente une portée de 15 mètres sans obstacle",
            "Compatible avec les appareils audio analogiques et numériques"
        ],
        file: [pdf4]
    },
    {
        id: 5,
        name: "ReSound Phone Clip+",
        image: img5,
        checklist: [
            "Diffuse les conversations téléphoniques et l'audio directement vers les aides auditives sans fil ReSound pour passer des appels téléphoniques main-libre",
            "Compatible avec les téléphones dotés de la technologie sans fil Bluetooth®",
            "Le bouton de mise en sourdine permet aux patients de bloquer les sons environnants pour faciliter la concentration sur les appels dans les situations bruyantes",
            "Sert également de télécommande de base pour permettre à l'utilisateur de contrôler le volume des aides auditives et de basculer entre les paramètres des programmes"
        ],
        file: [pdf5]
    },
    {
        id: 6,
        name: "Accessoire ReSound Remote Control 2",
        image: img6,
        checklist: [
            "Permet aux patients de changer de programme, d'ajuster le volume et de contrôler la diffusion sur leurs aides auditives en toute facilité et discrétion",
            "L'écran LCD à haut contraste affiche clairement les réglages des aides auditives en un coup d'œil",
            "Possibilité de régler indépendamment le volume des aides auditives et du son diffusé",
            "Comprend une touche de verrouillage pour éviter l'activation accidentelle d'un bouton dans un sac à main ou une poche"
        ],
        file: [pdf6]
    },

];


export { accessories }; 