import React from "react";
import Audiological from "../../assets/services/img1evaluation.jpeg";

const EvaluationAudiologique = () => {
  return (
    <div className=" flex items-start gap-9 flex-col md:flex-row">
      <div className=" relative w-[100%] md:w-[50%] h-[300px] md:h-[600px]">
        <img
          src={Audiological}
          alt="Audiological"
          className=" w-full h-full object-cover"
        />
      </div>
      <div className=" w-[100%] md:w-[50%] flex flex-col gap-4 pt-4">
        <h3 className=" lg:text-[28px] text-custom-primary font-medium capitalize">
          Évaluation audiologique
        </h3>
        <p className=" text-[#585858] text-[13px] lg:text-[15px]">
          À Maison Paramed, nos experts sont capables de
          diagnostiquer et de traiter tous les problèmes d'audition : perte
          d'audition, acouphènes, hyperacousie et misophonie, conformément aux
          directives et procédures internationales. Notre établissement est
          équipé pour guider nos patients de tous âges à travers les premières
          étapes du diagnostic, jusqu'à la fin du traitement. Voici un résumé de
          nos services de diagnostic :
        </p>
        <ul className=" list-[circle] pl-[40px] text-[13px] lg:text-[15px] text-[#585858]">
          <li className=""> Audiométrie tonale pure</li>
          <li>Audiométrie par renforcement visuel (VRA)</li>
          <li>Audiométrie d'observation comportementale</li>
          <li>Audiométrie par écoute</li>
          <li>Audiométrie vocale</li>
          <li>Test de la parole dans le bruit (SPIN)</li>
          <li>Tympanométrie (TYMP)</li>
          <li>Test du réflexe stapédien</li>
          <li>Test d'otoémission acoustique (OAE)</li>
        </ul>
      </div>
    </div>
  );
};

export default EvaluationAudiologique;
