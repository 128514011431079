import { Routes, Route } from 'react-router-dom'
// import { lazy } from 'react';
import Home from '../pages/Home';
import About from '../pages/About';
import WhyMaisonParamed from '../pages/WhyMaisonParamed';
import Teams from '../pages/Teams';
import { Services } from '../pages/Services';
import BlogHearingLoss from '../pages/blogs/BlogHearingLoss';
import BlogHearingProtection from '../pages/blogs/BlogHearingProtection';
import HearingSolution from '../pages/HearingSolution';
import HearingProtection from '../pages/HearingProtection';
import BlogMultiMic from '../pages/blogs/BlogMultiMic';
import ContactUs from '../components/ContactUs';
import HearingAids from '../pages/HearingAids';
import Compatibility from '../pages/Compatibility';
import Accessories from '../pages/Accessories';
import Interton from '../pages/Interton';
// const Home = lazy(()=> import("../pages/Home"))
// const About = lazy(()=> import("../pages/About"))
// const WhyMaisonParamed = lazy(()=> import("../pages/WhyMaisonParamed"))
// const Teams = lazy(()=> import("../pages/Teams"))

const Routers = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/maison-paramed' element={<WhyMaisonParamed />} />
        <Route path='/team' element={<Teams />} />
        <Route path='/services' element={<Services />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/comprende-la-perte-auditive' element={<BlogHearingLoss />} />
        <Route path='/protection-auditive-sur-mesure' element={<BlogHearingProtection />} />
        <Route path='/hearing-solutions' element={<HearingSolution />} />
        <Route path='/hearing-protection' element={<HearingProtection />} />
        <Route path='/pourquoi-vous-pourriez-avoir-besoin-multi-mic' element={<BlogMultiMic />} />
        <Route path='/devices' element={<Compatibility />} />
        <Route path='/hearing-aids/:brand' element={<HearingAids />} />
        <Route path='/hearing-aids/accessory/:id' element={<Accessories />} />
        <Route path='/hearing-aids/interton/:brand' element={<Interton />} />

      </Routes>
    </div>
  )
}

export default Routers