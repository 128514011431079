import React from "react";
import musicImage from "../../assets/hearing Protection/music.png";
import musicProduct from "../../assets/hearing Protection/music product.gif";
const Music = () => {
  return (
    <div className=" flex flex-col gap-5 lg:gap-1 lg:flex-row" data-aos="fade-zoom-in">
      <div className="  flex items-start lg:w-[50%]  gap-2">
       <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] w-[60%]">
            <img className=" absolute w-full h-full object-cover" src={musicImage} alt="" />
       </div>
       <div className=" relative h-[250px] md:h-[300px] lg:h-[500px] flex items-center justify-center w-[40%]">
         <img className=" w-full h-[150px]  object-cover" src={musicProduct} alt="" />
       </div>
      </div>
      <div className="lg:w-[50%] flex flex-col gap-6">
        <h2 className=" m-auto lg:m-0 capitalize lg:text-[25px] text-custom-primary">
          Filtres pour la musique/DJ
        </h2>
        <p  className=" text-[15px] text-[#5b636c] leading-8" >
          La protection de la musique, ou protection auditive pour les musiciens
          et les amateurs de musique, est tout aussi importante pour préserver
          la santé auditive. Les musiciens, les amateurs de concerts et les
          personnes qui écoutent fréquemment de la musique à des volumes élevés
          sont très exposés au risque de perte auditive due au bruit et à
          d'autres problèmes liés à l'audition.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8" >
          Les musiciens, les DJ et les fêtards évoluent dans des environnements
          bruyants et sont exposés à des niveaux sonores potentiellement
          dangereux. Une exposition prolongée à une musique forte peut entraîner
          des lésions auditives permanentes, des acouphènes (bourdonnements dans
          les oreilles) et des difficultés à distinguer les sons et les
          fréquences.
        </p>
        <p className="  text-[15px] text-[#5b636c] leading-8" >
          Pour protéger leur audition, les musiciens peuvent utiliser des
          protections auditives personnalisées et spécialisées conçues pour les
          musiciens (filtres). Ces filtres sont conçus pour réduire les niveaux
          sonores de manière uniforme sur différentes fréquences, préservant
          ainsi l'intégrité de la musique tout en réduisant le risque de
          dommages auditifs. Les filtres sont disponibles en 3 degrés
          d'atténuation, -9 dB, -15 dB, -25 dB. En donnant la priorité à la
          protection de la musique, les musiciens et les passionnés de musique
          peuvent profiter de leur passion tout en préservant leur audition.
        </p>
      </div>
    </div>
  );
};

export default Music;
