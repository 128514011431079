import React from "react";
import Measurements from "../../assets/services/Real-Ear-Measurements.jpg";
const Mesures = () => {
  return (
    <div className=" flex gap-3 md:gap-[20px] lg:gap-[50px] flex-col md:flex-row">
      <div className=" w-[100%] h-[250px] md:h-[300px] lg:h-[400px] relative md:w-[50%]">
        <img src={Measurements} alt="" className=" absolute w-full h-full object-cover" />
      </div>
      <div className=" w-[100%] md:w-[50%] pt-10">
        <div className=" flex flex-col gap-3">
          <h1 className="lg:text-[28px] text-custom-primary font-medium capitalize">
            Mesures de l'oreille réelle
          </h1>
          <ul className=" flex flex-col gap-4 pl-6 list-[circle] text-[#585858] text-[13px] lg:text-[15px]">
            <li>
              Les mesures de l'oreille réelle constituent la procédure de
              référence pour l'adaptation des prothèses auditives. Ces services
              sont rarement fournis par les cliniciens en raison du manque
              d'expertise et de connaissances et du coût.
            </li>
            <li>
              Ces mesures mécaniques permettent à l'audiologiste de mesurer le
              son lorsqu'il sort de l'aide auditive dans vos oreilles
            </li>
            <li>
              En utilisant cette technologie UNIQUEMENT, le clinicien peut être
              sûr que le patient a accès à tous les sons de la parole qui sont
              essentiels à la communication. Au lieu de se fier uniquement aux
              commentaires du patient, la combinaison de ces derniers avec la
              REM garantit la meilleure adaptation pour tout le monde
            </li>
            <li>
              Ceci est particulièrement important lors de l'équipement
              d'appareils auditifs pour enfants, car ils dépendent du son
              provenant de l'appareil auditif pour développer leur parole et
              leur langage. Tout problème mineur d'amplification peut entraîner
              des problèmes d'audition et de parole à long terme dont les
              parents peuvent ne pas être conscients.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Mesures;
