import React, { useEffect, useState } from "react";
import ProductDetails from "../components/ProductDetails";
import hearing_aids from "../utils/hearing-aids";
import { useParams } from "react-router-dom";
const HearingAids = () => {
  const { brand } = useParams();
  const [prod, setProd] = useState([]);

//  console.log(prod);
  useEffect(() => {  
    const filtered = hearing_aids.find(item => item.brand === brand);
    setProd(filtered); // Update filteredItems state with the filtered list
     
     
  }, [brand]);
       
       
  return (
    <div> 
      {
             prod ? <ProductDetails product={prod} />  : <span>loading ....</span>

      }
    </div>
  );
};

export default HearingAids;
