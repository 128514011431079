
import productsIcon from "../assets/hearingaid.png";
import servicesIcon from "../assets/agreement.png";
import technologyIcon from "../assets/technology.png";
import img1 from "../assets/product.jpg";
import service from "../assets/about/services.jpg";
import tech from "../assets/about/tech.jpg";
const menuLink = [
  {
    id: 1,
    title: "Nos produits",
    desc: "Nos fournisseurs officiels sont des entreprises reconnues internationalement qui sont à la pointe de la recherche et du développement en matière de perte auditive.",
    icon: productsIcon,
    image: img1,
    reverse: false,
    lien: "/hearing-solutions",
    show_button : true
  },
  {
    id: 2,
    title: "Nos services",
    desc: "Depuis l'ouverture de notre premier bureau, nos experts en audition ont aidé des dizaines de milliers de patients à renouer avec les sons qui leur manquaient.",
    icon: servicesIcon,
    image: service,
    reverse: true,
    lien: "/services",
    show_button : true
  },

  {
    id: 3,
    title: "Nos technologies",
    desc: "Avec des équipements de test de référence, des solutions auditives de pointe, un laboratoire de pointe utilisant des machines d'impression 3D et des scanners auriculaires, nous proposons des solutions rechargeables, des solutions invisibles ainsi que des solutions de connectivité.",
    icon: technologyIcon,
    image: tech,
    reverse: false,
    lien :'/',
    show_button : false
  },
];

export default menuLink;
