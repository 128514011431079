import award1 from "../assets/awards/award-1.png";
import award2 from "../assets/awards/award-2.png";
import award3 from "../assets/awards/award-3.png";
import award4 from "../assets/awards/award-4.png";
import award5 from "../assets/awards/award-5.png";
import award6 from "../assets/awards/award-6.png";


// La Liste des trophés 
export const AWARDS = [award1, award2, award3, award4, award5, award6];
