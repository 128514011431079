import React, { useState } from "react";
import image from "../assets/contactBg.jpg";
import TitleWithImage from "../components/TitleWithImage";
import { IoLocation, IoMail } from "react-icons/io5";
import { BsTelephoneFill } from "react-icons/bs";
import { saveContactToFireStore } from "../utils/firestoreFunctions";

function ContactUs() {
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const sendContact = (e) => {
    e.preventDefault();
    saveContactToFireStore(fullname, phone, email, message);
    setFullname("");
    setEmail("");
    setPhone("");
    setMessage("");
  };
  return (
    <section classname="">
      {/* ---------- BLOG IMAGE  */}
      <TitleWithImage image={image} title="contactez-nous" />

      <div className="flex lg:flex-row md:flex-col sm:flex-col  flex-col ">
        {/* ----------- INFO LOCATION */}
        <div className="py-8 px-8 flex flex-col justify-center leading-10 lg:w-2/5 ">
          <p className="text-lg font-bold text-custom-primary mb-4">
            Centre de Correction Auditif Maison Paramed
          </p>
          <div className="flex flex-row items-center mb-4">
            <IoLocation className=" text-[20px] text-custom-primary mr-4" />
            <p className="text-sm text-neutral-900 ">
              K.O.M, Lot. Al Wafaa, N° 15, Hay Al Farah, Sect. 4, Salé 11004
            </p>
          </div>
          <div className="flex flex-row items-center mb-4">
            <BsTelephoneFill className=" text-[20px] text-custom-primary mr-4" />
            <p className="text-sm text-neutral-900 ">+2125 37 83 12 03</p>
          </div>
          <div className="flex flex-row items-center mb-4">
            <IoMail className=" text-[20px] text-custom-primary mr-4" />
            <p className="text-sm text-neutral-900 ">maisonparamed@gmail.com</p>
          </div>
        </div>

        {/* ---------- Form  */}
        <div className="px-10 lg:w-3/5 ">
          <form action="" className=" py-8 ">
            <div className="mb-8">
              <p className="text-md text-custom-secondary">Nom Complet</p>
              <input
                type="text"
                class=" py-2 px-3 border outline-none font-extralight w-full "
                placeholder="nom et prénom*"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <p className="text-md text-custom-secondary">Téléphone</p>
              <input
                type="text"
                class=" py-2 px-3 border outline-none font-extralight w-full "
                placeholder="téléphone: 06xxxxxxxx*"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <p className="text-md text-custom-secondary">Email</p>
              <input
                type="text"
                class=" py-2 px-3 border outline-none font-extralight w-full "
                placeholder="email*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <p className="text-md text-custom-secondary">Message</p>
              <textarea
                name="Text1"
                class="w-full py-2 px-3 border outline-none font-extralight"
                rows="5"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button
              type="submit"
              className=" py-2 px-6  bg-custom-primary hover:bg-custom-primary/60 duration-200 transition-all  text-white rounded-md"
              onClick={sendContact}
            >
              Envoyer
            </button>
          </form>
        </div>
      </div>

      {/* --------- LOCATION CENTER */}
      <div className="max-w-4xl m-auto py-10 px-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.715853670466!2d-6.762525824327244!3d34.025503919141826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda76a7045e2a44b%3A0xf1720b1e45770cb4!2sCentre%20de%20Correction%20Auditif%20Maison%20Para-Med!5e0!3m2!1sen!2sma!4v1726251764237!5m2!1sen!2sma"
          class="w-full aspect-video"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>
    </section>
  );
}

export default ContactUs;
