// import allSolution  from "../assets/solutions/all-solutions.jpg"
// import connectivity from "../assets/solutions/connectivity-solutions.jpg"
import invisiblehearing from "../assets/solutions/invisible-solutions.jpg"
import img1 from '../assets/solutions/img1.jpeg'; 
import img2 from '../assets/solutions/img2.jpeg'; 

const  solutions = [
    {
        id:1,
        name:"toutes les solutions",
        image: img1,
        lien:"/hearing-solutions"
    },
    {
        id:2,
        name:"Aides Auditives",
        image: img2,
        lien:"/hearing-solutions"
    },
    {
        id:3,
        name:"Accessoires",
        image: invisiblehearing,
        lien:"/hearing-solutions"
    }
    

]


 export default solutions;