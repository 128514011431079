import React from "react";
import TitleWithImage from "../components/TitleWithImage";
import Title from "../components/Title";
const Teams = () => {
  // const personnes = [
  //   {
  //     id: 1,
  //     name: "Mohamed Ahmed",
  //     jobTitle: "General Manager",
  //     text: '"ensemble dans votre parcours auditif"',
  //     image: TEAMS.GeneralManager,
  //   },
  //   {
  //     id: 2,
  //     name: "Maria EL Modden",
  //     jobTitle: "Audiologist",
  //     text: '"Je suis tout ouïe""',
  //     image: TEAMS.Audiologist,
  //   },
  //   {
  //     id:3,
  //     name: "Khadija EL Taqui",
  //     jobTitle: "Chef Audiologist",
  //     text: '"Votre audition est ma priorité"',
  //     image: TEAMS.Asmae,
  //   },

  // ];
  return (
    <div>
      {/* <TitleWithImage title="équipe maison paramed" />
      <Title title="Rencontrez les experts" />
      <div className="px-4 sm:px-8 md:px-16 lg:px-24 xl:px-36 gap-2 md:gap-28 lg:gap-32 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
        {personnes.map((personne, index) => (
          <div key={index} className=" px-3 md:p-4 flex flex-col md:px-0 gap-3  items-center ">
            <img className=" w-full h-[385px]" src={personne.image} alt={personne.jobTitle} />
            <p className=" text-center italic text-custom-primary capitalize">{personne.text}</p>
            <h1 className=" text-[#585959] font-medium">{personne.name}</h1>
            <h3 className=" font-light underline text-custom-primary/90">{personne.jobTitle}</h3>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Teams;