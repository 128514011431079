import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { TiSocialInstagram } from "react-icons/ti";
import { IoMenu } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import pourquoiParamed from "../assets/services/img3.jpeg";
import team from "../assets/about/team.jpg";
import { SERVICES } from "../utils/IMAGES";
import solutions from "../utils/hearingSolution";
import hearingProtection from "../utils/hearingProtection";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState("");
  const [showSlide, setShowSlide] = useState("");
  const Cards = [
    {
      name: "Pourquoi Maison Paramed",
      image: pourquoiParamed,
      lien: "/maison-paramed",
    },
    // {
    //   name: "l'équipe",
    //   image: team,
    //   lien: "/team",
    // },
  ];

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <header className=" -z-50">
        <div className=" flex justify-between items-center px-4  bg-custom-primary">
          <ul className=" flex items-center gap-3 text-custom-third">
            {/* <li className=" font-bold text-[14px] md:text-[16px] lg:text-[18px]">
              <a href="/">
                {" "}
                <FaFacebookF />
              </a>
            </li>
            <li className=" font-bold text-[18px]">
              <a href="/">
                {" "}
                <FaLinkedinIn />
              </a>
            </li> */}
            <li className=" font-bold text-[18px]">
              <a href="https://www.instagram.com/mpm1.maroc" target="_blank"  rel="noreferrer">
                {" "}
                <TiSocialInstagram />
              </a>
            </li>
          </ul>
          <ul className="flex items-center  text-custom-third text-[15px]">
            <li className="py-3 px-3 ">
              {/* <a href="/contactus" className="font-semibold hover:">Contactez-nous</a> */}
              <a
                href="/contactus"
                className="text-custom-third font-semibold transition duration-300 ease-in-out hover:underline hover:scale-105"
              >
                Contactez-nous
              </a>
            </li>
          </ul>
        </div>
        <div
          className={` ${
            scrollPosition >= 48
              ? " lg:fixed shadow shadow-gray-800 top-0 bg-white z-50"
              : ""
          } w-full flex justify-between items-center px-4 lg:flex-col xl:flex-row xl:items-center lg:items-start`}
        >
          <div className=" logo">
            <a href="/">
              <img
                className="w-[100px] md:w-[130px] lg:w-[180px] cursor-pointer"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <ul className="  items-center gap-4  h-full lg:justify-end pb-3 hidden lg:flex w-full">
            <li
              onMouseEnter={() => setShowSlide("about")}
              onMouseLeave={() => setShowSlide("")}
            >
              <a
                className=" relative hover:text-custom-primary text-gray-500  py-[40px] text-[14px] font-normal h-full"
                href="/about"
              >
                À propos de Paramed
              </a>
              <div
                onMouseEnter={() => setShowSlide("about")}
                onMouseLeave={() => setShowSlide("")}
                className={`${
                  showSlide === "about" ? "  h-[350px]" : " "
                } overflow-hidden mt-[11px] xl:mt-[39px] h-0 transition-all duration-200  ease-in-out absolute  left-0  z-40 w-full  flex justify-around bg-white text-custom-primary`}
              >
                {Cards.map((item, index) => (
                  <a
                    href={item.lien}
                    key={index}
                    onClick={() => setShowSlide("")}
                  >
                    <div className=" relative flex flex-col gap-1 justify-center items-center">
                      <img
                        className=" h-[300px] py-4 cursor-pointer "
                        src={item.image}
                        alt=""
                      />
                      <h3>{item.name}</h3>
                    </div>
                  </a>
                ))}{" "}
              </div>
            </li>

            <li
              onMouseEnter={() => setShowSlide("service")}
              onMouseLeave={() => setShowSlide("")}
            >
              <a
                className="hover:text-custom-primary text-gray-500 py-[40px] text-[14px]  h-full font-normal"
                href="/services"
              >
                Nos services
              </a>
              <div
                onMouseEnter={() => setShowSlide("service")}
                onMouseLeave={() => setShowSlide("")}
                className={`${
                  showSlide === "service" ? "  h-[250px]" : " "
                }  overflow-hidden mt-[11px] xl:mt-[39px] h-0 transition-all duration-200  ease-in-out absolute  left-0  z-40 w-full  flex justify-around bg-white text-custom-primary`}
              >
                {SERVICES.map((item, index) => (
                  <a
                    href={item.lien}
                    key={index}
                    onClick={() => setShowSlide("")}
                  >
                    <div className=" relative flex flex-col gap-3 justify-center items-center overflow-hidden">
                      <img
                        className="h-[200px] lg:w-[200px] xl:w-[240px] py-4 px-4 object-cover transform transition duration-200 ease-in-out hover:scale-110 cursor-pointer "
                        src={item.image}
                        alt=""
                      />
                      <h3 className=" m-auto w-fit text-center capitalize">
                        {item.name}
                      </h3>
                    </div>
                  </a>
                ))}{" "}
              </div>
            </li>
            <li
              onMouseEnter={() => setShowSlide("solutions")}
              onMouseLeave={() => setShowSlide("")}
            >
              <a
                className=" hover:text-custom-primary text-gray-500 py-[40px] text-[14px] h-full font-normal"
                href="/hearing-solutions"
              >
                Solutions auditives
              </a>
              <div
                onMouseEnter={() => setShowSlide("solutions")}
                onMouseLeave={() => setShowSlide("")}
                className={`${
                  showSlide === "solutions" ? "  h-[300px]" : " "
                } overflow-hidden mt-[11px] gap-8 px-4 xl:mt-[39px] h-0 transition-all duration-200  ease-in-out absolute  left-0  z-40 w-full  flex justify-around bg-white text-custom-primary`}
              >
                {solutions.map((item, index) => (
                  <a
                    href={item.lien}
                    key={index}
                    onClick={() => setShowSlide("")}
                  >
                    <div className=" relative flex flex-col gap-1 justify-center items-center">
                      <img
                        className=" h-[220px] py-4 object-cover transform transition duration-200 ease-in-out hover:scale-110 cursor-pointer "
                        src={item.image}
                        alt=""
                      />
                      <h3 className=" capitalize">{item.name}</h3>
                    </div>
                  </a>
                ))}{" "}
              </div>
            </li>
            <li
              onMouseEnter={() => setShowSlide("protection")}
              onMouseLeave={() => setShowSlide("")}
            >
              <a
                className=" hover:text-custom-primary text-gray-500 py-[40px] text-[14px]  h-full font-normal"
                href="/hearing-protection"
              >
                Protection auditive
              </a>
              <div
                onMouseEnter={() => setShowSlide("protection")}
                onMouseLeave={() => setShowSlide("")}
                className={`${
                  showSlide === "protection" ? "  h-[300px]" : " "
                } overflow-hidden mt-[11px] gap-8 px-4 xl:mt-[39px] h-0 transition-all duration-200  ease-in-out absolute  left-0  z-40 w-full  flex justify-around bg-white text-custom-primary`}
              >
                {hearingProtection.map((item, index) => (
                  <a
                    href={item.lien}
                    key={index}
                    onClick={() => setShowSlide("")}
                  >
                    <div className=" relative flex flex-col gap-1 justify-center items-center">
                      <img
                        className=" h-[220px] lg:w-[260px] xl:w-[300px] py-4 object-cover transform transition duration-200 ease-in-out hover:scale-110 cursor-pointer "
                        src={item.image}
                        alt=""
                      />
                      <h3 className=" m-auto w-fit text-center capitalize">
                        {item.name}
                      </h3>
                    </div>
                  </a>
                ))}{" "}
              </div>
            </li>
            {/* <li>
              <a
                className="hover:text-custom-primary text-gray-500 py-[40px] text-[14px]  h-full font-normal"
                href="/"
              >
                Nos ressources
              </a>
            </li>
            <li>
              <a
                className=" hover:text-custom-primary text-gray-500   py-[40px] text-[14px]  h-full font-normal"
                href="/"
              >
                Test auditif en ligne
              </a>
            </li> */}
          </ul>
          <div className=" lg:hidden">
            <IoMenu
              className=" text-[23px] text-gray-500 cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
        {
          // mobile device
          <div
            className={`${
              isOpen ? `h-[500px] py-4` : "h-0"
            }  w-full bg-white  transition-all duration-300 overflow-hidden`}
          >
            <ul className=" text-[14px] text-gray-500 transition-all duration-300 ">
              <li>
                <a
                  className=" py-3 border-t border-gray-300   px-4 font-light w-full flex items-center justify-between"
                  href="/about"
                >
                  À propos de Paramed <FaAngleDown />
                </a>
              </li>
              {/* <li>
                <a
                  onClick={() =>
                    showMenu === "parcours auditif"
                      ? setShowMenu("")
                      : setShowMenu("parcours auditif")
                  }
                  className=" py-3 border-t border-gray-300   px-4 font-light w-full flex items-center justify-between"
                  href="/#"
                >
                  Votre parcours auditif <FaAngleDown />
                </a>
               {
                  <ul
                    className={`${
                      showMenu === "parcours auditif" ? "h-fit" : "h-0"
                    } px-2 list-disc overflow-hidden transition  duration-300`}
                  >
                    <li>
                      <a
                        className=" py-3 border-t border-gray-300   px-4 font-light w-full flex items-center justify-between"
                        href="/#"
                      >
                        À propos de Paramed <FaAngleDown />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" py-3 border-t border-gray-300   px-4 font-light w-full flex items-center justify-between"
                        href="/#"
                      >
                        À propos de Paramed <FaAngleDown />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" py-3 border-t border-gray-300   px-4 font-light w-full flex items-center justify-between"
                        href="/#"
                      >
                        À propos de Paramed <FaAngleDown />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" py-3 border-t border-gray-300   px-4 font-light w-full flex items-center justify-between"
                        href="/#"
                      >
                        À propos de Paramed <FaAngleDown />
                      </a>
                    </li>
                  </ul>
                }
              </li> */}
              <li>
                <a
                  href="/services"
                  className=" py-3 border-t border-gray-300  px-4 font-light  w-full flex items-center justify-between"
                >
                  Nos services <FaAngleDown />
                </a>
              </li>
              <li>
                <a
                  className="  py-3 border-t border-gray-300 w-full flex items-center justify-between px-4 font-light"
                  href="/hearing-solutions"
                >
                  Solutions auditives <FaAngleDown />
                </a>
              </li>
              <li>
                <a
                  className=" py-3 border-t border-gray-300 w-full flex items-center justify-between px-4  font-light"
                  href="/hearing-protection"
                >
                  Protection auditive <FaAngleDown />
                </a>
              </li>
              {/* <li>
                <a
                  className=" py-3 border-t border-gray-300 w-full flex items-center justify-between px-4 font-light"
                  href="/"
                >
                  Nos ressources <FaAngleDown />
                </a>
              </li>
              <li>
                <a
                  className="py-3 border-t border-gray-300 w-full flex items-center justify-between  px-4 font-light"
                  href="/"
                >
                  Test auditif en ligne <FaAngleDown />
                </a>
              </li> */}
            </ul>
          </div>
        }
      </header>
    </div>
  );
};

export default Header;
